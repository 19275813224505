import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { fetchUrl } from "Urls";
import SendIcon from "../../assets/images/send.svg";
import {
   Box,
   IconButton,
   MenuItem,
   MenuList,
   Popper,
   ClickAwayListener,
   Paper,
   CircularProgress
} from "@mui/material";
import { cloneDeep } from "lodash";

const ChatterComponent = (props) => {
   const { parentId, boxStyle, userList } = props;
   const [isLoading, setLoading] = useState(false);
   const [userData, setUserDataState] = useState(userList ? userList : []);
   const userDataRef = useRef(userData);
   const setUserData = (state) => {
      userDataRef.current = state;
      setUserDataState(state);
   };
   const [postId, setPostIdState] = useState();
   const postIdRef = useRef(postId);
   const setPostId = (state) => {
      postIdRef.current = state;
      setPostIdState(state);
   };
   const [commentValue, setCommentValue] = useState("");
   const [comments, setCommentsList] = useState([]);
   const contentEditableRef = useRef(null);

   useEffect(() => {
      if (parentId) {
         setCommentsList([]);
         setCommentValue('');
         fetchChatterComments();
      }
   }, [parentId]);

   const [showDropdown, setShowDropdown] = useState(false);
   const [dropdownAnchor, setDropdownAnchor] = useState(null);
   const [searchText, setSearchText] = useState("");
   const [filteredUsers, setFilteredUsers] = useState([]);

   useEffect(() => {
      if (userList?.length > 0) {
         let temp = cloneDeep(userList);
         let uData = createUserData(temp);
         setUserData(uData);
      }
      setCommentValue('');
      if (contentEditableRef.current) {
         contentEditableRef.current.innerHTML = '';
      }
   }, [userList]);

   const createUserData = (users) => {
      const labelCount = {};  // To keep track of label occurrences
      return users.map(user => {
         const { value, label } = user;
         const baseLabel = label;
         let newLabel = label;
         if (labelCount[label]) {
            labelCount[label] += 1;
            newLabel = `${baseLabel}${labelCount[label]}`;
         } else {
            labelCount[label] = 1;
         }
         return {
            value,
            label: newLabel
         };
      });
   };
   useEffect(() => {
      if (searchText.length > 0 && userData?.length > 0) {
         const filtered = userData.filter((user) =>
            user.label?.toLowerCase().includes(searchText.toLowerCase())
         );
         //console.log('filtered :: ', filtered?.length);
         setFilteredUsers(filtered);
      } else {
         setFilteredUsers([]);
      }
   }, [searchText, userData]);

   const fetchChatterComments = async () => {
      setLoading(true);
      await axios.post(`${fetchUrl}/get-chatter-post?parentId=${parentId}`)
      .then(async (res) => {
         //console.log('feed data :: ', JSON.stringify(res.data));
         if (res?.status == 200) {
            let rrr = res.data;
            setPostId(rrr.postId);
            //console.log('rrr.comments :: ',JSON.stringify(rrr.comments));
            let cmts = []
            if (rrr.comments?.length > 1) {
               cmts = rrr.comments.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
            }else{
               cmts = rrr.comments;
            }
            setCommentsList(cmts);
         }
      }).catch((err) => {
         console.log('fetch feed ::', err);
      });
      setLoading(false);
   }
   const handlePostComment = async (cmt) => {
      if (cmt && cmt?.length > 0) {
         let segments = getEncodedMessageSegments(cmt, userDataRef.current);
         if (segments?.length > 0) {
            setLoading(true);
            let req_body = { messageSegments: segments }
            await axios.post(`${fetchUrl}/post-chatter-comment/${postId}`, req_body)
            .then(async (res) => {
               if (res?.status == 200) {
                  fetchChatterComments();
               }
            }).catch((err) => {
               console.log('fetch feed ::', err);
            });
         }
         setLoading(false);
         setCommentValue('');
         contentEditableRef.current.innerHTML = '';
      }
   };
   const handleSearchComment = (event) => {
      const value = event.target.innerText;
      //console.log('value ::', value);
      setCommentValue(value);
      const lastAtSymbolIndex = value.lastIndexOf("@");
      if (lastAtSymbolIndex !== -1) {
         const textAfterAt = value.substring(lastAtSymbolIndex + 1).trim();
         const words = textAfterAt.split(" ");
         let showAll = false;
         if(lastAtSymbolIndex === value?.length - 1){
            showAll = true;
         }
         // Check if search should be triggered (1 word after @)
         if ((textAfterAt.length > 0 && words.length === 1 && words[0] !== "") || showAll) {
            if(showAll){
               setFilteredUsers(userDataRef.current);
            }else{
               setSearchText(textAfterAt);
            }
            setShowDropdown(true);
            setDropdownAnchor(event.currentTarget);
         } else {
            setShowDropdown(false);
         }
      } else {
         setShowDropdown(false);
      }
   };
   const handleUserSelect = (user) => {
      const atIndex = commentValue.lastIndexOf("@");
      const newCommentValue = commentValue.substring(0, atIndex) + "@[" + user.label + "] ";
      setCommentValue(newCommentValue);
      setShowDropdown(false);
      contentEditableRef.current.innerHTML = formatCommentInputBox(newCommentValue);
   };
   //Function to parse and style the comment
   const formatCommentInputBox = (text) => {
      const mentionRegex = /@\[[^\]]+\]/g;
      let formattedText = "";
      let lastIndex = 0;
      const matches = [...text.matchAll(mentionRegex)];
      if (matches.length === 0) {
         return text;
      }
      matches.forEach((match) => {
         const matchIndex = match.index;
         const mentionText = match[0];
         formattedText += text.substring(lastIndex, matchIndex);
         formattedText += `<span class="mention">${mentionText}</span>`;
         lastIndex = matchIndex + mentionText.length;
      });
      formattedText += text.substring(lastIndex);
      return formattedText;
   };

   return (
      <>
         <div style={{ position: "relative", display: "flex", alignItems: "center", border: "1px solid #ccc", borderRadius: "4px" }}>
            <div
               ref={contentEditableRef}
               data-placeholder={commentValue?.length > 0 ? '' : 'Add a comment. Use @ to mention'}
               contentEditable
               suppressContentEditableWarning={true}
               className="chatter-comment-input"
               onInput={(e) => handleSearchComment(e)}
            />
            <IconButton onClick={() => {
               if (commentValue && commentValue?.length > 0 && postId) {
                  handlePostComment(commentValue);
               }
            }} disabled={isLoading}>
               <img src={SendIcon} alt="send_icon" />
            </IconButton>
         </div>
         {showDropdown && dropdownAnchor && filteredUsers?.length > 0 && (
            <Popper
               id="basic-poper"
               anchorEl={dropdownAnchor}
               open={showDropdown}
               onClose={() => { setShowDropdown(false) }}
               style={{ zIndex: 9999 }}
            >
               <Paper sx={{ maxHeight: 320, overflow: 'auto', width: "100%" }}>
                  <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
                     <MenuList>
                        {filteredUsers.map((user) => (
                           <MenuItem key={user.value} onClick={() => handleUserSelect(user)}>
                              {user.label}
                           </MenuItem>
                        ))}
                     </MenuList>
                  </ClickAwayListener>
               </Paper>
            </Popper>
         )}
         {isLoading && 
            <div style={{display:'flex' , justifyContent:'center' , padding:'5px 0px'}}>
               <CircularProgress size={16} />
            </div>
         }
         {comments?.length > 0 &&
            <div className='commentListContainer' style={boxStyle}>
               {comments.map((cmt) => (
                  <div key={cmt.id} className="comment_box_item">
                     <Box className="person_comment">
                        <CommentOutput messageSegments={cmt.body.messageSegments}></CommentOutput>
                        {/* {cmt.body.text} */}
                     </Box>
                     <Box className="assigned_date">
                        <Box className="user_details">
                           <img src="user.svg" alt="avatar" height={16} width={16} />
                           <span className="username">
                              {cmt.user.displayName}
                           </span>
                        </Box>
                        <Box>
                           <span className="username">
                              {cmt.relativeCreatedDate}
                           </span>
                        </Box>
                     </Box>
                  </div>
               ))}
            </div>
         }
      </>
   );
}
export default ChatterComponent;
// CommentOutput
export const CommentOutput = ({ messageSegments }) => {
   const commentOutput = useRef(null);
   const [rerender , setRerenderer] = useState(0);
   useEffect(() => {
      const createHtmlFromSegments = (segments) => {
         let html = '';
         segments.forEach(segment => {
            switch (segment.type) {
               case 'MarkupBegin':
                  if (segment.htmlTag === 'a') {
                     html += `<a href="${segment.url}" alt="${segment.altText}">`;
                  } else {
                     html += `<${segment.htmlTag}>`;
                  }
                  break;
               case 'MarkupEnd':
                  html += `</${segment.htmlTag}>`;
                  break;
               case 'Text':
                  html += segment.text;
                  break;
               case 'Mention':
                  html += `<span class="mention" title="${segment.name}">${segment.text}</span>`;
                  break;
               default:
                  break;
            }
         });
         return html;
      };
      if (commentOutput.current) {
         //console.log('messageSegments :: ',messageSegments?.length );
         if(messageSegments?.length > 0){
            commentOutput.current.innerHTML = createHtmlFromSegments(messageSegments);
         }
      }else{
         //console.log('not found messageSegments :: ',messageSegments?.length );
         setRerenderer((pre)=>pre++);
      }
   }, [messageSegments , rerender]);
   return <div style={{width: '100%', wordBreak: 'break-all', overflowWrap: 'break-word', whiteSpace: 'normal'}} ref={commentOutput}></div>;
};

export const getEncodedMessageSegments = (cmt, userList = []) => {
   const urlRegex = /https?:\/\/[^\s]+|www\.[^\s]+|[^\s]+\.[^\s]+/gi;
   const mentionRegex = /@\[(.*?)\]/g;
   let msgSegments = [];
   if (cmt) {
      let paragraphs = cmt.split('\n');
      paragraphs.forEach(paragraph => {
         msgSegments.push({ type: "MarkupBegin", markupType: "Paragraph" });
         let lastIndex = 0;
         let match;
         let mentionMatches = [];
         let urlMatches = [];
         while ((match = mentionRegex.exec(paragraph)) !== null) {
            mentionMatches.push({ index: match.index, label: match[1] });
         }
         while ((match = urlRegex.exec(paragraph)) !== null) {
            urlMatches.push({ index: match.index, url: match[0] });
         }
         mentionMatches.sort((a, b) => a.index - b.index);
         urlMatches.sort((a, b) => a.index - b.index);
         let allMatches = [...mentionMatches.map(m => ({ ...m, type: 'mention' })),
         ...urlMatches.map(u => ({ ...u, type: 'url' }))];
         allMatches.sort((a, b) => a.index - b.index);
         allMatches.forEach(match => {
            if (match.index > lastIndex) {
               let preText = paragraph.substring(lastIndex, match.index);
               if (/\s+$/.test(preText)) {
                  preText = preText.replace(/\s+$/, (spaces) => '&nbsp;'.repeat(spaces.length));
               }
               msgSegments.push({ type: "text", text: preText });
            }
            if (match.type === 'mention') {
               let user = userList.find(u => u.label === match.label);
               if (user) {
                  msgSegments.push({ type: "mention", id: user.value });
               } else {
                  msgSegments.push({ type: "text", text: `@[${match.label}]` });
               }
            } else if (match.type === 'url') {
               let url = match.url;
               let formattedUrl = url.startsWith('http') ? url : `https://${url}`;
               if (!formattedUrl.endsWith('/')) {
                  formattedUrl += '/';
               }
               if (/\.\s/.test(paragraph.substring(match.index, match.index + url.length + 1))) {
                  let textSegment = url;
                  if (/\s+$/.test(textSegment)) {
                     textSegment = textSegment.replace(/\s+$/, (spaces) => '&nbsp;'.repeat(spaces.length));
                  }
                  msgSegments.push({ type: "text", text: textSegment });
               } else {
                  msgSegments.push({ type: "MarkupBegin", markupType: "Hyperlink", url: formattedUrl, altText: formattedUrl });
                  msgSegments.push({ type: "text", text: url });
                  msgSegments.push({ type: "MarkupEnd", markupType: "Hyperlink" });
               }
            }
            lastIndex = match.index + (match.type === 'mention' ? match.label.length + 3 : match.url.length);
         });
         if (lastIndex < paragraph.length) {
            let remainingText = paragraph.substring(lastIndex);
            if (/\s+$/.test(remainingText)) {
               remainingText = remainingText.replace(/\s+$/, (spaces) => '&nbsp;'.repeat(spaces.length));
            }
            msgSegments.push({ type: "text", text: remainingText });
         }
         msgSegments.push({ type: "MarkupEnd", markupType: "Paragraph" });
      });
   }
   return msgSegments;
};