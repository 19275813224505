import { useState, useEffect, useRef } from "react";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import { Tabs, Tab } from "@mui/material";
import { Search } from "@mui/icons-material";
import { getInternalRecordUrl} from "api";
import moment from "moment";
import Spinner from "components/spinner";
import IssueDetailPanelDialog from "components/SetsView/IssueDetailPanel";
import _, { find } from "lodash";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import errorRed from "assets/images/error-red.svg";
import axios from "axios";
import { fetchUrl } from "Urls";
import SaveIssueDialog from "./SaveIssueDialog";
import {save_as_draft_toast, publish_issue_toast, change_save_issue_toast, change_closed_issue_toast, change_amend_issue_toast, change_void_issue_toast} from "components/SetsView/IssuePanel";
import ToastComponent from "components/ToastComponent";
import DropdownFilter from "components/DropdownFilter";
import Design from "components/SheetsComponent/Design";
import { highlightText } from "components/SheetsComponent";
import { Tooltip, Box, Button, IconButton, TextField, InputAdornment, Checkbox, Stack, Paper, Menu } from "@mui/material";
import moveIcon from "assets/images/move.svg";
import draftIcon from "assets/images/draft.svg";
import voidIcon from "assets/images/void.svg";
import compareIcon from "assets/images/compare.svg";
import pendingIcon from "assets/images/pending.svg";
import answeredIcon from "assets/images/answered.svg";
import closedGreenIcon from "assets/images/closed.svg";
import amendIcon from "assets/images/amend.svg";
import FilesComponentStyle from "components/FilesComponent/FilesComponentStyle";
import ReactDataTable from "components/ReactDataTableComponent";
import { issueStatusToColorMap , updateColorForIssueXML ,removeIssueMarkupFromXML} from "components/SetsView/ShowPdf";
import { ConfirmDialog } from "components/DrawerComponent/DisciplineSettingDialog";

export async function updateMarkupOnLinkedDocumentGlobal(credential , recId , remove){
  const reqBody = {fields : ['Annotation_Id__c','Status__c', 'Linked_Document__c' , 'Linked_Document__r.Markup_Json__c' ] , filter: `Id='${recId}' AND Linked_Document__c != null AND Annotation_Id__c != null` };
  await axios.post(`${fetchUrl}/query/Issue__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, reqBody)
  .then(async (res) => {
    if (res.status === 200) {
      const re_data = res.data;
      if (re_data.status == 200) {
        const issu = re_data.records[0];
        const ccolor = issueStatusToColorMap[issu?.Status__c] ? issueStatusToColorMap[issu?.Status__c].pillColor : issueStatusToColorMap['Draft'].pillColor;
        var xfdfString;
        if(remove === true){
          xfdfString = removeIssueMarkupFromXML(issu.Linked_Document__r.Markup_Json__c , issu.Annotation_Id__c);
        }else{
          xfdfString = updateColorForIssueXML(issu.Linked_Document__r.Markup_Json__c , issu.Annotation_Id__c , ccolor);
        }
        console.log('xfdfString :: ',issu.Annotation_Id__c,xfdfString , ccolor);
        if(xfdfString){
          let obj = { Id: issu.Linked_Document__c, Markup_Json__c: xfdfString };
          const reqBody = new FormData();
          reqBody.append("recObj", JSON.stringify(obj));
          await axios.post(`${fetchUrl}/recSave/Amazon_S3_Attachment__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, reqBody)
          .then(async (res) => {
            if (res?.data?.status === 200) {
            }
          });
        }
      }
    }
  }).catch(e => {
    console.log("error >>>>>>>>>>>>", JSON.stringify(e));
    console.error(e);
  });
}
const closedIssueStatus = ["Draft", "Closed", "Void"];
const IssuesComponent = (props) => {
  const { userData = {} } = useSelector((state) => state.userData);
  const [_projectId, setProjectId] = useState();
  const [credential, setCredential] = useState(userData);
  const [activeTab, setActiveTabState] = useState(0);
  const activeTabRef = useRef(activeTab);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showSpinner, setSpinner] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [selectedMenuRow, setSelectedMenuRow] = useState();
  const [openDetail, setOpenDetail] = useState(false);
  const [openDetailRecord, setOpenDetailRecord] = useState();
  const currentTabIssueListRef = useRef([]);
  const [currentTabIssueList, setCurrentTabIssueListState] = useState([]);
  const [fetchedIssueList, setFetchedIssueList] = useState([]);
  const [assigntoMeListData, setAssigntoMeListData] = useState([]);
  const [myIssueListData, setMyIssueListData] = useState([]);
  const [viewListData, setViewListData] = useState(fetchedIssueList);
  const [createNewIssue, setCreateNewIssue] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [rowsPerPage, setRowsPerPage] = useState([]);
  const [deleteDialogData, setDeleteDialogData] = useState();
  const errorMessage = "No available issues";
  const errorMessageSearch = "None of issue matched this search";

  /** filter variables start */
  const [filteredTableData, setFilteredTableData] = useState([]);
  const processTofilter_ = useRef(null);
  const [activeFilterDropdown, setActiveFilterDropdown] = useState(false);
  const [totalFilterApplied, setTotalFilterApplied] = useState(false);
  const [filterOptionsVisible, showFilterOptions] = useState(false);
  const [issueOptionObj, setIssueOptionFieldObj] = useState(credential?.issueObjOptions);

  const [issueTypeActiveFilters, setIssueTypeActiveFilters] = useState([]);
  const [issueStatusActiveFilters, setIssueStatusActiveFilters] = useState([]);
  const [issueLocationActiveFilters, setIssueLocationActiveFilters] = useState( [] );
  const [issueRootCauseActiveFilters, setIssueRootCauseActiveFilters] = useState([]);
  const [createdByActiveFilters, setCreatedByActiveFilters] = useState([]);
  const [assignedToActiveFilters, setAssignedToActiveFilters] = useState([]);

  /** filter variables end */

  /** search vriables start */
  const [searchText, setSearchText] = useState("");
  const [searchBoxFilter, setSearchBoxFilter] = useState(false);
  /** search vriables end */

  const linkedDocumentOption = [
    { value: "Sheet", label: "Sheet" },
    { value: "File", label: "File" },
    { value: "None", label: "None" },
  ];
  const columns = [
    {
      id: "checkbox",
      accessor: "",
      disableSortBy: true,
      Header: (
        <Checkbox
          style={{ padding: "0px", marginLeft: "10px" }}
          sx={{ padding: "0px", "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" } }}
          checked={selectedRowIds.length > 0 && selectedRowIds.length === rowsPerPage?.length}
          onChange={(e) =>{
            onRowSelectionAllChange(e.target.checked);
          }}
        />
      ),
      Cell: (rowInfo) => {
        return (
          <div onClick={(e)=>{
            e.stopPropagation(); // to stop triggering rowClick Event
          }}>
          <Checkbox
            style={{ padding: "0px", marginLeft: "10px" }}
            sx={{ "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" } }}
            type="checkbox"
            className="checkbox"
            checked={selectedRows.filter((row) => row.id === rowInfo?.row?.original?.id).length > 0}
            onChange={(e) =>{
              e.stopPropagation();
              onRowSelectionChange(rowInfo?.row?.original?.id, e.target.checked)
            }}
          />
          </div>
        );
      },
      overrideStyle: { width: "4%" },
    },
    {
      accessor: "numberSequence",
      Header: "Id",
      overrideStyle: { width: "4%" },
      Cell: (params) => (
        <div className="table_id">
          <Box className="id_content">
            <span
              dangerouslySetInnerHTML={{
                __html: params.row?.original?.numberSequence_mark
                  ? params.row?.original?.numberSequence_mark
                  : params.row?.original?.numberSequence,
              }}
            />
          </Box>
        </div>
      ),
    },
    {
      accessor: "title",
      Header: "Issue Name",
      flex: 1,
      overrideStyle: { width: "15%" },
      Cell: (params) => (
        <span onClick={(e) => {
          e.stopPropagation();
          handleIssueClick(params.row.original.record)
        }}
          dangerouslySetInnerHTML={{
            __html: params.row?.original?.title_mark
              ? params.row?.original?.title_mark
              : params.row?.original?.title,
          }}
          style={{cursor: 'pointer'}}
        />
      ),
    },
    {
      accessor: "status",
      Header: "Status",
      flex: 1,
      overrideStyle: { width: "15%" },
      Cell: (params) => {
        return (
          <>
            {params.value == "Draft" && (
              <div className="table_id" style={{ display: "flex" }}>
                <img
                  style={{ paddingRight: "5px" }}
                  src={draftIcon}
                  alt="draftIcon"
                />
                <Box className="id_content">{params.value}</Box>
              </div>
            )}
            {params.value == "Closed" && (
              <div className="table_id" style={{ display: "flex" }}>
                <img
                  style={{ paddingRight: "5px" }}
                  src={closedGreenIcon}
                  alt="closedGreenIcon"
                />
                <Box className="id_content">{params.value}</Box>
              </div>
            )}
            {params.value == "Amend Resolution" && (
              <div className="table_id" style={{ display: "flex" }}>
                <img
                  style={{ paddingRight: "5px" }}
                  src={amendIcon}
                  alt="amendIcon"
                />
                <Box className="id_content">{params.value}</Box>
              </div>
            )}
            {params.value == "Answered" && (
              <div className="table_id" style={{ display: "flex" }}>
                <img
                  style={{ paddingRight: "5px" }}
                  src={answeredIcon}
                  alt="answeredIcon"
                />
                <Box className="id_content">{params.value}</Box>
              </div>
            )}
            {params.value == "Pending" && (
              <div className="table_id" style={{ display: "flex" }}>
                <img
                  style={{ paddingRight: "5px" }}
                  src={pendingIcon}
                  alt="pendingIcon"
                />
                <Box className="id_content">{params.value}</Box>
              </div>
            )}
            {params.value == "Void" && (
              <div className="table_id" style={{ display: "flex" }}>
                <img
                  style={{ paddingRight: "5px" }}
                  src={voidIcon}
                  alt="voidIcon"
                />
                <Box className="id_content">{params.value}</Box>
              </div>
            )}
          </>
        );
      },
    },
    {
      accessor: "type",
      Header: "Type",
      flex: 1,
      overrideStyle: { width: "15%" },
    },
    {
      accessor: "assigned_to",
      Header: "Ball in Court",
      flex: 1,
      overrideStyle: { width: "15%" },
    },
    {
      accessor: "due_date",
      Header: "Due Date",
      flex: 1,
      overrideStyle: { width: "15%" },
    },
    {
      accessor: "linkdocument",
      Header: " Linked Document",
      flex: 1,
      overrideStyle: { width: "20%" },
      Cell: (params) => {
        let row = params.row.original;
        return (
          <div title={params.value}>
            {row.inappurl && 
             <a href={row.inappurl}>{params.value}</a>
            }
            {!row.inappurl &&
              <>
                {params.value}
              </>
            }
          </div>
        );
      },
      disableSortBy: true,
    },
    {
      accessor: "settings",
      Header: "settings",
      flex: 1,
      disableSortBy: true,
      overrideStyle: { width: "4%" },
    },
  ];
  useEffect(() => {
    //console.log('files use effect ::',userData?.projectId,_projectId);
    if (_projectId !== userData?.projectId) {
      if (userData?.projectId) {
        //console.log("files use effect :: in ");
        setProjectId(userData?.projectId);
        setCredential(userData);
        refreshTableList(userData);
      }
    }
  }, [userData?.projectId]);
  useEffect(() => {
    setCredential(userData);
  }, [userData?.userList]);
  useEffect(() => {
    //console.log('projectId :: '+projectId);
    setViewListData(currentTabIssueList);
    onHandleFilterData();
  }, [currentTabIssueList]);
  useEffect(() => {
    let cc = 0;
    if (issueTypeActiveFilters.length > 0) {
      cc += issueTypeActiveFilters.length;
    }
    if (issueStatusActiveFilters.length > 0) {
      cc += issueStatusActiveFilters.length;
    }
    if (issueLocationActiveFilters.length > 0) {
      cc += issueLocationActiveFilters.length;
    }
    if (issueRootCauseActiveFilters.length > 0) {
      cc += issueRootCauseActiveFilters.length;
    }
    if (createdByActiveFilters.length > 0) {
      cc += createdByActiveFilters.length;
    }
    if (assignedToActiveFilters.length > 0) {
      cc += assignedToActiveFilters.length;
    }
    setTotalFilterApplied(cc);
    if (processTofilter_.current) {
      onHandleFilterData(cc);
    }
    processTofilter_.current = false;
  }, [
    issueTypeActiveFilters,
    issueStatusActiveFilters,
    issueLocationActiveFilters,
    issueRootCauseActiveFilters,
    createdByActiveFilters,
    assignedToActiveFilters,
  ]);
  function setActiveTab_(tabValue) {
    activeTabRef.current = tabValue;
    setActiveTabState(tabValue);
  }
  function setCurrentTabIssueList(values) {
    currentTabIssueListRef.current = values;
    setCurrentTabIssueListState(values);
  }
  function handleIssueClick(record) {
    setOpenDetailRecord(record);
    setOpenDetail(true);
  }
  function onActiveTabChange(tabValue) {
    setSpinner(true);
    setActiveTab_(tabValue);
    setCurrentTabIssueList(getCurrentViewData(fetchedIssueList));
    searchListViewFunction('');
    setSpinner(false);
  }
  const getCurrentViewData = (fetchedIssueList_ = fetchedIssueList , setValueToState) => {
    let retunList = [];
    let asignedToMe = [];
    let myIssues = [];
    if(fetchedIssueList_?.length > 0){
      if(activeTabRef.current === 0){
        retunList =  JSON.parse(JSON.stringify(fetchedIssueList_));
      }
      for (let issue of fetchedIssueList_) {
        let row = JSON.parse(JSON.stringify(issue));
        if (row.assigned_toId === credential?.userId && (!closedIssueStatus.includes(row.status) || row.status === 'Draft')) {
          if(activeTabRef.current === 1){
            retunList.push(row);
          }
          asignedToMe.push(row);
        }
        if (row.createdById === credential?.userId) {
          if(activeTabRef.current === 2){
            retunList.push(row);
          }
          myIssues.push(row);
        }
      }
    }
    if(setValueToState === true){
      setAssigntoMeListData(asignedToMe);
      setMyIssueListData(myIssues);
    }
    return retunList;
  };
  const onRowSelectionAllChange = (isChecked) => {
    setSelectedRowIds([]);
    let rowIds = [];
    //console.log('rowsPerPage :: ',rowsPerPage?.length,isChecked);
    if (isChecked === true) {
      rowsPerPage.map((row) => rowIds?.push(row.id));
    }
    setSelectedRowIds(rowIds);
    const selRowIds = new Set(rowIds);
    const seleFolRows = viewListData.filter((row) =>selRowIds.has(row.id.toString()));
    setSelectedRows(seleFolRows);
  }
  const onRowSelectionChange = (rowId, isChecked) => {
      //console.log('rowsPerPage :: ',rowsPerPage?.length,rowId,isChecked,selectedRowIds?.length);
      let rowIds = [];
      if (isChecked === true) {
        rowIds = selectedRowIds.length > 0 ? selectedRowIds : [];
        rowIds?.push(rowId);
      } else {
        rowIds = selectedRowIds.length > 0 ? selectedRowIds.filter((item) => item !== rowId) : [];
      }
      //console.log('rowIds :: ',rowIds?.length);
      setSelectedRowIds(rowIds);
      const selRowIds = new Set(rowIds);
      const seleFolRows = viewListData.filter((row) =>selRowIds.has(row.id.toString()));
      setSelectedRows(seleFolRows);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedMenuRow(null);
    console.log('handleCloseMenu called');
  };
  const handleClickMenu = (event, parms) => {
    //console.log('handleClickMenu :: ',parms);
    event.preventDefault();
    event.stopPropagation();
    setSelectedMenuRow(parms.row);
    setAnchorEl(event.currentTarget);
  };
  async function onClickOpenAction(action, clickSource) {
    //console.log('handleCloseMenu called' , selectedMenuRow);
    //console.log('handleCloseMenu called' , selectedRows);
    let dialogData;
    if (action === 'delete') {
      const rowItem = clickSource === 'menu' ? selectedMenuRow : selectedRows[0] ;
      dialogData = {
        title: `Delete ${rowItem.title}`,
        content: `Are you sure you want to delete the selected issue? This cannot be undone.`,
        action1: "Cancel",
        action2: "Delete",
        source: clickSource,
        operation: action,
        operationItems : [rowItem]
      };
      setDeleteDialogData(dialogData);
    } else if (action === 'void') {
      const rowItem = clickSource === 'menu' ? selectedMenuRow : selectedRows[0] ;
      dialogData = {
        title: `Void ${rowItem.title}`,
        content: `Are you sure you want to void selected issue? Once void, issue cannot be edited. This cannot be undone.`,
        action1: "Cancel",
        action2: "Void",
        source: clickSource,
        operation: action,
        operationItems : [rowItem]
      };
      setDeleteDialogData(dialogData);
    }
    setAnchorEl(false);
  }
  const onClickVoidItemSingle = async (restoreItem = selectedMenuRow) => {
      //console.log("onClickRestoreItemSingle_ restoreItem :: ", restoreItem);
      setDeleteDialogData(null);
      if (restoreItem) {
        //console.log('deleteDialog ::',deleteDialog_);
        let issRec = restoreItem?.record;
        issRec.Status__c = 'Void';
        let toastMessage = `${restoreItem.title} has been voided.`;
        onClickSave(issRec , {message : toastMessage} , true);
        setOpenDetail(false);
        setOpenDetailRecord(null);
      }
  };
  const onClickDeleteItemSingle = async (restoreItem = selectedMenuRow) => {
      //console.log("onClickRestoreItemSingle_ restoreItem :: ", restoreItem);
      setDeleteDialogData(null);
      if (restoreItem) {
        //console.log('deleteDialog ::',deleteDialog_);
        setSpinner(true);
        await updateMarkupOnLinkedDocumentGlobal( credential, restoreItem.id , true);
        let toastMessage = `${restoreItem.title} has been deleted.`;
        await axios.delete(`${fetchUrl}/delete/Issue__c/${restoreItem.id}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`)
          .then((res) => {
            setToastMessage(toastMessage);
            refreshTableList();
            setOpenDetail(false);
            setOpenDetailRecord(null);
        }).catch((err) => {
          console.log(err);
          setSpinner(false);
        });
      }
  };
  async function refreshTableList(_credential = credential) {
    handleCloseIcon();
    setCurrentTabIssueList([]);
    setViewListData([]);
    setFetchedIssueList([]);
    setAssigntoMeListData([]);
    setMyIssueListData([]);
    fetchIssueLists(_credential);
  }
  async function fetchIssueLists(_credential = credential) {
    setSpinner(true);
    var allIssues = [];
    await axios.get(`${fetchUrl}/issueLists/${_credential?.projectId}?token=${_credential?.token}&instanceUrl=${_credential?.instanceUrl}`)
    .then((res) => {
      if (res?.status === 200) {
        const re_data = res.data;
        if (re_data?.status === 200) {
          allIssues = _.map(re_data?.issues, (row) => { return prepareSobjectToIssueTableRowElement(row , _credential); });
        }}
      })
      .catch((err) => {
        setSpinner(false);
        console.error(err);
      });
    setFetchedIssueList(allIssues);
    setCurrentTabIssueList(getCurrentViewData(allIssues , true));
    setSpinner(false);
  }
  const handleClearAllFilters = () => {
    processTofilter_.current = true;
    setViewListData(getCurrentViewData(fetchedIssueList));
    setActiveFilterDropdown(undefined);
    setIssueTypeActiveFilters([]);
    setIssueStatusActiveFilters([]);
    setIssueLocationActiveFilters([]);
    setIssueRootCauseActiveFilters([]);
    setCreatedByActiveFilters([]);
    setAssignedToActiveFilters([]);
    onHandleFilterData(0);
  };
  async function onHandleFilterData(_totalFilterApplied = totalFilterApplied) {
    //console.log("running ::");
    let filteredFoundList = [];
    let orgData = cloneDeep(currentTabIssueListRef?.current);
    if (orgData?.length > 0 && _totalFilterApplied > 0) {
      for (const currRow of orgData) {
        let matched = true;
        if (issueTypeActiveFilters?.length > 0 && matched === true) {
          matched = false;
          if (currRow.type) {
            matched = issueTypeActiveFilters.includes(currRow.type);
          }
        }
        if (issueStatusActiveFilters?.length > 0 && matched === true) {
          matched = false;
          if (currRow.status) {
            matched = issueStatusActiveFilters.includes(currRow.status);
          }
        }
        if (issueLocationActiveFilters?.length > 0 && matched === true) {
          matched = false;
          if (currRow.location) {
            matched = issueLocationActiveFilters.includes(currRow.location);
          }
        }
        if (issueRootCauseActiveFilters?.length > 0 && matched === true) {
          matched = false;
          if (currRow.rootCause) {
            matched = issueRootCauseActiveFilters.includes(currRow.rootCause);
          }
        }
        if (assignedToActiveFilters?.length > 0 && matched === true) {
          matched = false;
          const valueFound = assignedToActiveFilters.find(
            (usss) => usss.value === currRow.assigned_toId
          );
          if (valueFound) {
            matched = true;
          }
        }
        if (createdByActiveFilters?.length > 0 && matched === true) {
          matched = false;
          const valueFound = createdByActiveFilters.find(
            (usss) => usss.value === currRow.createdById
          );
          if (valueFound) {
            matched = true;
          }
        }
        if (matched) {
          filteredFoundList.push(currRow);
        }
      }
    } else {
      filteredFoundList = orgData;
    }
    //console.log('onHandleFilterData filteredFoundList :: '+filteredFoundList?.length);
    setFilteredTableData(filteredFoundList);
    setViewListData(filteredFoundList);
  }
  async function searchListViewFunction(searchTerm) {
    setSearchBoxFilter(false);
    setSearchText(searchTerm);
    let orgData = filteredTableData?.length > 0 || totalFilterApplied > 0 ? cloneDeep(filteredTableData) : cloneDeep(currentTabIssueList);
    //console.log(searchTerm,'orgData :: '+orgData?.length);
    let searchFoundList = orgData;
    if (orgData) {
      if (searchTerm && searchTerm !== null && searchTerm !== "") {
        searchFoundList = [];
        for (let el of orgData) {
          if ( (el.numberSequence && el.numberSequence.toLowerCase().includes(searchTerm)) || (el.title && el.title.toLowerCase().includes(searchTerm)) ) {
            if ( el.numberSequence && el.numberSequence.toLowerCase().includes(searchTerm) ) {
              el.numberSequence_mark = highlightText( el.numberSequence, searchTerm );
            }
            if (el.title && el.title.toLowerCase().includes(searchTerm)) {
              el.title_mark = highlightText(el.title, searchTerm);
            }
            searchFoundList.push(el);
          }
        }
        setSearchBoxFilter(true);
      }
    }
    //console.log(searchTerm, 'searchFoundList :: ' + searchFoundList?.length);
    setViewListData(searchFoundList);
  }
  const handleCloseIcon = () => {
    setSelectedRowIds([]);
    setSelectedRows([]);
    setDeleteDialogData(null);
  };
  const handleClose = (refresh) => {
    setCreateNewIssue(false);
    setSpinner(false);
    if(refresh){
      fetchIssueLists();
    }
  };
  async function onClickSave(issueObj, info , checkSpinner) {
    issueObj["Project__c"] = credential?.projectId;
    //console.log('issueObj :: ',JSON.stringify(issueObj));
    if(checkSpinner){
      setSpinner(true);
    }
    const formData = new FormData();
    //let issueObj = sfIssueObj;
    formData.append("issueObj", JSON.stringify(issueObj));
    let tM;
    await axios.post( `${fetchUrl}/saveIssue/${credential?.projectId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData )
      .then(async (res) => {
        if (res.status === 200) {
          issueObj.Id = res.data.id;
          if(info?.callFrom === 'Issue Detail Panel'){
            setOpenDetail(false);
            setOpenDetailRecord(issueObj);
            setOpenDetail(true);
          }
          if(fetchedIssueList?.length > 0){
            let foundIssue = fetchedIssueList.find(usr => usr.id === issueObj.Id );
            //console.log('foundIssue :: ',foundIssue);
            if(foundIssue){
              if(foundIssue?.record.Linked_Document__c && foundIssue?.record.Annotation_Id__c){
                updateMarkupOnLinkedDocumentGlobal(credential , issueObj.Id);
              }
            }
          }
          tM = info?.message;
          if(!tM && info?.saveAs){
            if(info?.saveAs == 'Owner Draft new'){
              tM = "Issue has been created";
            }else if(info?.saveAs == "Owner DraftId") {
              tM = "Issue has been saved";
            }else if(info?.saveAs == 'Owner Draft'){
              tM = save_as_draft_toast ;
            }else if(info?.saveAs == 'Owner Publish'){
              tM = publish_issue_toast ;
            }else if(info?.saveAs == 'BIC RSubmit'){
              tM = "Resolution Submitted";
            }else if(info?.saveAs == 'Owner Save'){
              tM = change_save_issue_toast;
            }else if(info?.saveAs == 'Owner Closed Issue'){
              tM = change_closed_issue_toast;
            }else if(info?.saveAs == 'Owner Amend Resolution'){
              tM = change_amend_issue_toast;
            }else if(info?.saveAs == 'Owner Void'){
              tM = change_void_issue_toast;
            }
          }
          if(tM){
            let idReplace = issueObj.Numbering_Sequence__c !=null ? `ID #${issueObj.Numbering_Sequence__c} ` : '';
            tM = tM.replace('{id}',idReplace);
            setToastMessage(tM);
          }
          refreshTableList();
          handleClose(false);
        }
      })
      .catch((error) => {
        handleClose(false);
        setSpinner(false);
        console.log("error >>>>>>>>>>>>", JSON.stringify(error));
        console.error(error);
      });
  }
  return (
    <Box sx={{ display: "flex" }}>
      <Box component="main" sx={{ flexGrow: 1, width: { sm: "100%" }, height: `calc(100vh - 113px)`, overflow: "hidden", }} >
        {(_projectId || true) && (
          <FilesComponentStyle>
            <div className="wrapper_main">
              <div className="table_head">
                <div className="header_heading">
                  <h1>Issues</h1>
                </div>
                <div className="right_side">
                  <Button component="button" className="custom_button apply_button" onClick={() => { setCreateNewIssue(true); }} >
                    Create Issue
                  </Button>
                </div>
              </div>
              <div className="tab_wrapper" style={{ paddingLeft: "10px" }}>
                <Tabs value={activeTab} textColor="primary" indicatorColor="primary" onChange={(event, newValue) => { onActiveTabChange(newValue); }} >
                  <Tab label="All Issues" />
                  <Tab
                    label={
                      <div className="publish-logs">
                        <div className="text-and-kpi">
                          <div className="label poppins-normal-blue-14px">
                            {" "}
                            Issues Assigned to me
                          </div>
                          <div className="kpi">
                            <div className="x1 poppins-normal-white-10px">
                              {" "}
                              {assigntoMeListData.filter((issue)=> !closedIssueStatus.includes(issue.status)).length}{" "}
                            </div>
                          </div>{" "}
                        </div>
                      </div>
                    }
                  />
                  <Tab
                    label={
                      <div className="publish-logs">
                        <div className="text-and-kpi">
                          <div className="label poppins-normal-blue-14px">
                            {" "}
                            My Issues
                          </div>
                          <div className="kpi">
                            <div className="x1 poppins-normal-white-10px">
                              {" "}
                              {myIssueListData.filter((issue)=> !closedIssueStatus.includes(issue.status)).length}{" "}
                            </div>
                          </div>{" "}
                        </div>
                      </div>
                    }
                  />
                </Tabs>
              </div>
              <div className="page_body" style={{width: openDetail && "calc(100% - 400px)"}}>
                <div style={{ width: "100%"}}>
                  <div className="page_body_right_head">
                    <div className="page_body_right_head_left_side">
                      <>
                        <div style={{ display: "flex",alignSelf: "center", marginRight: "10px", borderRight: "1px solid #EAEAEA" }} >
                          {selectedRowIds.length > 0 && (
                            <div className="buttons" style={{ paddingBottom: "4px", cursor: "pointer", }} onClick={handleCloseIcon} >
                              <img className="close" src="close.svg" alt="close" style={{ cursor: "pointer" }} />
                            </div>
                          )}
                          <div style={{marginRight: "10px"}}>
                            <h2>{selectedRowIds.length} Selected</h2>
                          </div>
                        </div>
                        {selectedRowIds.length > 0 && (
                          <div style={{ borderRight: "1px solid #EAEAEA", borderTop: "1px solid #EAEAEA", borderBottom: "1px solid #EAEAEA", borderLeft: "1px solid #EAEAEA", display: "flex", }} >
                            <div style={{ borderRight: "1px solid #EAEAEA" }}>
                              <Tooltip PopperProps={{className:"version-action-tooltip"}} title="Export" placement="top" arrow>
                                <IconButton sx={{ borderRadius: 0 }}>
                                  <img src="/download.svg" alt="share" />
                                </IconButton>
                              </Tooltip>
                            </div>
                            {selectedRowIds?.length === 1 && selectedRows[0]?.createdById === credential?.userId && (
                              <>
                                {selectedRows[0]?.status !== 'Void' &&  selectedRows[0]?.status !== 'Closed' &&
                                  <div style={{ borderRight: "1px solid #EAEAEA" }}>
                                    <Tooltip PopperProps={{className:"version-action-tooltip"}} title="Delete" placement="top" arrow>
                                      <IconButton sx={{ borderRadius: 0 }} onClick={()=>{onClickOpenAction('delete','header')}}>
                                        <img src="/delete.svg" alt="share" />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                }
                                {selectedRows[0]?.status !== 'Void' &&
                                  <div style={{ borderRight: "1px solid #EAEAEA" }}>
                                    <Tooltip PopperProps={{ className: "version-action-tooltip" }} title="Void" placement="top" arrow>
                                      <IconButton sx={{ borderRadius: 0 }} onClick={() => { onClickOpenAction('void', 'header') }}>
                                        <img src={voidIcon} alt="voidIcon" />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                }
                              </>
                            )}
                            {/* <div style={{ borderRight: "1px solid #EAEAEA" }}>
                              <Tooltip PopperProps={{className:"version-action-tooltip"}} title="Compare" placement="top" arrow>
                                <IconButton sx={{ borderRadius: 0 }}>
                                  <img src={compareIcon} alt="comp" />
                                </IconButton>
                              </Tooltip>
                            </div> */}
                          </div>
                        )}
                      </>
                    </div>

                    <div className="page_body_right_head_right_side">
                      <div>
                        <TextField
                          sx={{
                            "& .MuiInputBase-root": {
                              color: "#505050",
                              height: "37px",
                              padding: "4px",
                              width: "300px",
                            },
                          }}
                          id="outlined-basic"
                          variant="outlined"
                          value={searchText}
                          fullWidth
                          className="Searchbar-field"
                          autoComplete="off"
                          placeholder="Search Issue ID / Issue Name"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                {searchBoxFilter ? (
                                  <img
                                    style={{ width: "25px", cursor: "pointer" }}
                                    src="/cancel.svg"
                                    alt="close icon"
                                    onClick={() => {
                                      searchListViewFunction("");
                                      setSearchText("");
                                    }}
                                  />
                                ) : (
                                  <Search />
                                )}
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            //convert input text to lower case
                            var lowerCase = e.target.value.toLowerCase();
                            if ( !lowerCase || lowerCase === null || lowerCase === "" ) {
                              searchListViewFunction(lowerCase);
                            }
                            setSearchText(lowerCase);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              // Do code here
                              e.preventDefault();
                              var lowerCase = e.target.value.toLowerCase();
                              //console.log('searchText ',searchText , 'lowerCase',lowerCase);
                              searchListViewFunction(searchText);
                            }
                          }}
                        />
                      </div>
                      <div className={"grid"} style={{ width: "114px" }}>
                        <img className="filter2" src="/download.svg" alt="filter2" />
                        <div className="label-11 poppins-normal-abbey-14px">
                          Export All
                        </div>
                      </div>
                      <div
                        className={ totalFilterApplied > 0 ? filterOptionsVisible ? "grid-11" : "grid-111"
                            : filterOptionsVisible ? "grid-1" : "grid" }
                        onClick={() => {
                          showFilterOptions(!filterOptionsVisible);
                        }}
                      >
                        <img className="filter2" src="/filter2 (1).svg" alt="filter2" />
                        <div className="label-11 poppins-normal-abbey-14px">
                          Filters{" "} {totalFilterApplied > 0 && ( <>{" (" + totalFilterApplied + ")"}</> )}
                        </div>
                        {!filterOptionsVisible && totalFilterApplied > 0 && (
                          <div className={ filterOptionsVisible ? "grid-1" : "grid-2" } onClick={handleClearAllFilters}>
                            <div className="clear1">
                              <img className="clear" src="close.svg" alt="close" />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {searchBoxFilter && (
                    <div className="bottom_area" style={{ paddingBottom: "16px", paddingLeft: "16px", fontSize: "14px", fontWeight: 400, }} >
                      {searchBoxFilter && (
                        <>
                          {" "}
                          {viewListData.length} Search Results for{" "}
                          <b>"{searchText}"</b>
                        </>
                      )}
                    </div>
                  )}
                  {filterOptionsVisible && (
                    <Design>
                      <div className="bottom_area" style={{ paddingBottom: "16px" }} >
                        <div className="filters">
                          <DropdownFilter
                            dropLabel="Type"
                            isValueArray={true}
                            options={issueOptionObj?.Type__c?.valueList}
                            activeFilterDropdown={activeFilterDropdown}
                            setActiveFilterDropdown={setActiveFilterDropdown}
                            activeFilters={issueTypeActiveFilters}
                            onFilterChange={async (newValues) => {
                              processTofilter_.current = true;
                              setIssueTypeActiveFilters(newValues);
                            }}
                          />
                          <DropdownFilter
                            dropLabel="Status"
                            isValueArray={true}
                            options={issueOptionObj?.Status__c?.valueList}
                            activeFilterDropdown={activeFilterDropdown}
                            setActiveFilterDropdown={setActiveFilterDropdown}
                            activeFilters={issueStatusActiveFilters}
                            onFilterChange={async (newValues) => {
                              processTofilter_.current = true;
                              setIssueStatusActiveFilters(newValues);
                            }}
                          />
                          <DropdownFilter
                            credential={credential}
                            dropLabel="Ball In Court"
                            options={credential?.userList}
                            activeFilterDropdown={activeFilterDropdown}
                            setActiveFilterDropdown={setActiveFilterDropdown}
                            activeFilters={assignedToActiveFilters}
                            onFilterChange={async (newValues) => {
                              processTofilter_.current = true;
                              //console.log('updatedByActiveFilters ::', newValues);
                              setAssignedToActiveFilters(newValues);
                            }}
                          />
                          <DropdownFilter
                            dropLabel="Root Cause"
                            isValueArray={true}
                            options={issueOptionObj?.Root_Cause__c?.valueList}
                            activeFilterDropdown={activeFilterDropdown}
                            setActiveFilterDropdown={setActiveFilterDropdown}
                            activeFilters={issueRootCauseActiveFilters}
                            onFilterChange={async (newValues) => {
                              processTofilter_.current = true;
                              setIssueRootCauseActiveFilters(newValues);
                            }}
                          />
                          <DropdownFilter
                            credential={credential}
                            dropLabel="Created By"
                            options={credential?.userList}
                            activeFilterDropdown={activeFilterDropdown}
                            setActiveFilterDropdown={setActiveFilterDropdown}
                            activeFilters={createdByActiveFilters}
                            onFilterChange={async (newValues) => {
                              processTofilter_.current = true;
                              //console.log('updatedByActiveFilters ::', newValues);
                              setCreatedByActiveFilters(newValues);
                            }}
                          />
                          {totalFilterApplied > 0 && (
                            <>
                              <div style={{ cursor: "pointer" }} className="tertiary" onClick={handleClearAllFilters} >
                                <img className="reset" src="reset.svg" alt="reset" />
                                <div className="filters">Clear All</div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </Design>
                  )}
                  <Box className="react_table_new" style={{ height: filterOptionsVisible ? searchBoxFilter ? "calc(100vh - 300px)": "calc(100vh - 270px)" : searchBoxFilter ? "calc(100vh - 260px)" : "calc(100vh - 230px)",}}>
                    {showSpinner && <Spinner />}
                    <ReactDataTable
                      errorMessage={searchBoxFilter ?errorMessageSearch : errorMessage}
                      pagination={true}
                      setRowsPerPage={setRowsPerPage}
                      columns_react_table={columns}
                      rowss={ viewListData }
                      openMenu={openMenu}
                      handleClickMenu={handleClickMenu}
                      selectedIds={selectedRowIds}
                      listCount={viewListData.length}
                      searchBoxFilter={searchBoxFilter}
                      onRowClick={(evt , row)=>{
                        handleIssueClick(row.original.record)
                      }}
                    />
                    {openDetail &&
                      <IssueDetailPanelDialog
                        sfObj={openDetailRecord}
                        credential={credential}
                        saveCallback={(sf, info) => {
                          //console.log('issue :: ', JSON.stringify(sf), JSON.stringify(info));
                          onClickSave(sf, info , true);
                        }}
                        closeCallBack={(annId) => {
                          setOpenDetail(false);
                        }}
                        callFrom="IssueTab"
                      />
                     }
                  </Box>
                </div>
              </div>
              {createNewIssue && (
                <SaveIssueDialog
                  onCancel={() => {
                    handleClose(false);
                  }}
                  saveCallback={onClickSave}
                  credential={credential}
                  linkedDocumentOption={linkedDocumentOption}
                  callFrom="IssuesComponent"
                />
              )}
            </div>
            {toastMessage &&
              <ToastComponent message={toastMessage} handleClose={() => setToastMessage(null)} />
            }
            {openMenu && selectedMenuRow && (
              <>
                <Stack direction="row" spacing={2}>
                  <Paper sx={{ height: 320, maxWidth: "100%" }}>
                    <Menu id="basic-menu" anchorEl={anchorEl} open={openMenu} onClose={handleCloseMenu}
                      MenuListProps={{ "aria-labelledby": "basic-button" }} >
                      <div className="dropdown-list">
                        <div className="text-with-icon-1" onClick={(e) => { onClickOpenAction('export', 'menu'); }}>
                          <img className="download-source" src='/download.svg' alt="export" />
                          <div className="label-3 poppins-normal-abbey-14px">
                            {" "} Export
                          </div>
                        </div>
                        {selectedMenuRow?.createdById === credential?.userId && 
                          <>
                            {selectedMenuRow?.status !== 'Void' &&  selectedMenuRow?.status !== 'Closed' &&
                              <div className="text-with-icon-1" onClick={(e) => { onClickOpenAction('delete', 'menu'); }}>
                                <img className="download-source" src="/delete.svg" alt="download source" />
                                <div className="label-3 poppins-normal-abbey-14px">
                                  {" "}
                                  Delete
                                </div>
                              </div>
                            }
                            {selectedMenuRow?.status !== 'Void' &&
                              <div className="text-with-icon-1" onClick={() => { onClickOpenAction('void', 'menu'); }} >
                                <img className="share" src={voidIcon} alt="void" />{" "}
                                <div className="label-1 poppins-normal-abbey-14px">
                                  {" "}
                                  Void
                                </div>
                              </div>
                            }
                          </>
                        }
                        {/* <div className="text-with-icon-1" onClick={() => { onClickOpenAction('compare', 'menu'); }}>
                          <img className="share" src={compareIcon} alt="share" />{" "}
                          <div className="label-1 poppins-normal-abbey-14px">
                            {" "}
                            Compare
                          </div>
                        </div> */}
                      </div>
                    </Menu>
                  </Paper>
                </Stack>
              </>
            )}
            {deleteDialogData &&
              <ConfirmDialog
                onCancel={() => { setDeleteDialogData(null); }}
                onAccept={() => {
                  if(deleteDialogData.source === 'menu' || deleteDialogData.operation === 'void'
                      || deleteDialogData.operation === 'delete' ){
                    if (deleteDialogData.operation === "void") {
                      onClickVoidItemSingle(deleteDialogData.operationItems[0]);
                    } else {
                      onClickDeleteItemSingle(deleteDialogData.operationItems[0]);
                    }
                  }else{

                  }
                }}
                DialogData={deleteDialogData}
              />
            }
          </FilesComponentStyle>
        )}
      </Box>
    </Box>
  );
};
export default IssuesComponent;

export function prepareSobjectToIssueTableRowElement(issueSobj , cred) {
  let tablePayload = {
    record: issueSobj,
    assigned_to: issueSobj?.Ball_in_Court__r?.Name
      ? issueSobj?.Ball_in_Court__r?.Name
      : "-",
    assigned_toId: issueSobj?.Ball_in_Court__c ? issueSobj?.Ball_in_Court__c : "-",
    due_date: issueSobj?.Due_Date__c ? issueSobj?.Due_Date__c : "-",
    createdBy: issueSobj?.CreatedById ? issueSobj?.CreatedBy?.Name : "-",
    createdById: issueSobj?.CreatedById ? issueSobj?.CreatedById : "-",
    createdDate: issueSobj?.CreatedDate ? issueSobj?.CreatedDate : "-",
    numberSequence: issueSobj?.Numbering_Sequence__c
      ? issueSobj?.Numbering_Sequence__c.toString()
      : "",
    id: issueSobj?.Id ? issueSobj?.Id : "",
    key: issueSobj?.Id ? issueSobj?.Id : "",
    location: issueSobj?.Location_Details__c ? issueSobj?.Location_Details__c : "-",
    type: issueSobj?.Type__c ? issueSobj?.Type__c : "-",
    title: issueSobj?.Name ? issueSobj?.Name : "-",
    status: issueSobj?.Status__c ? issueSobj?.Status__c : "-",
    company: "-",
    attachment: 1,
    comment: 1,
    linkdocument: issueSobj?.Linked_Document__r?.File_Name__c
      ? issueSobj?.Linked_Document__r?.File_Name__c
      : "-",
    linkDocumentObj: issueSobj?.Linked_Document__c
      ? { Id: issueSobj?.Linked_Document__c, ...issueSobj?.Linked_Document__r }
      : undefined,
    rootCause: issueSobj?.Root_Cause__c ? issueSobj?.Root_Cause__c : "-",
    owner: issueSobj?.OwnerId ? issueSobj?.Owner?.Name : "-",
    description: issueSobj?.Description__c ? issueSobj?.Description__c : "",
    response: issueSobj?.Response__c ? issueSobj?.Response__c : "",
  };
  //console.log('dfdf');
  if(issueSobj?.Linked_Document__r?.Folder__r?.Id){
    //console.log('dfdf 2');
    let folder = issueSobj?.Linked_Document__r?.Folder__r;
    let file = issueSobj?.Linked_Document__r;
    let inAppUrl; 
    if(folder.Folder_Type__c = 'Plan'){
      tablePayload.linkdocument = file.Document_Number__c ? file.Document_Number__c : file.File_Name__c ;
      inAppUrl = getInternalRecordUrl(cred , 'Sheets' , issueSobj?.Linked_Document__c);
      inAppUrl += `&issueId=${issueSobj?.Id}`;
    }else if(folder.Folder_Type__c = 'Project File'){
      tablePayload.linkdocument = file.Document_Title__c ? file.Document_Title__c : file.File_Name__c ;
      inAppUrl = getInternalRecordUrl(cred , 'Files' , issueSobj?.Linked_Document__c);
      inAppUrl += `&issueId=${issueSobj?.Id}`;
      inAppUrl += `&folderId=${folder?.Id}`;
    }
    tablePayload.inappurl = inAppUrl;
  }
  return tablePayload;
}
