import React, { useEffect, useRef, useState } from "react";
import WebViewer, { getInstance } from '@pdftron/webviewer';
import { IconButton, TextField, Tooltip, Checkbox, Stack, Paper, Menu, Box } from "@mui/material";
import grid from "assets/images/grid.svg";
//import moment from "moment";
import GridViewComponent from "components/FolderTable/GridViewComponent";
import axios from "axios";
import { fetchUrl } from "Urls";
import { makeRandomId , getAttachment ,handleAuxClick} from "api";
import _, { cloneDeep } from "lodash";
import navLeft from "assets/images/left_nav (1).svg";
import { ExportFileDialog } from "components/FolderTable/ExportFile";
import more from "assets/images/more.svg";
import folderIcon from "assets/images/folder.svg";
import editIcon from "assets/images/pencil.svg";
import pdfRed from "assets/images/pdfRed.svg";
import rightPath from "assets/images/right_path.svg";
import copyIcon from "assets/images/copy.svg";
import moveIcon from "assets/images/move.svg";
import DropdownFilter from "components/DropdownFilter";
import { Search } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import ReactDataTable from "components/ReactDataTableComponent";
import ToastComponent from "components/ToastComponent";
import Spinner from "components/spinner";
import { NewFolderDialog, MoveDialog, RecordRenameDialog } from "components/DrawerComponent/DisciplineSettingDialog";
import RecycleBin from "components/FolderTable/RecycleBin";
import Design from "components/SheetsComponent/Design";
import { ConfirmDialog } from "components/DrawerComponent/DisciplineSettingDialog";
import { highlightText, prepareAmazonRecordRow, prepareFolderRecordRow,
        searchTextInPdfContentWebViewer, getPdfThumbnailFromWebViewer, downloadPdfDocument } from "components/SheetsComponent";
import FileUploadProgress from "components/MultifileProcessing/multiFileProcessing";
import { APRYSE_L_KEY } from "api";
import { Uploader } from "api";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import ItemInQueueComponent from "components/ItemInQueueComponent";
import ShareableLinkComponent from "components/ShareableLinkComponent";
import copyLinkIcon from "components/ShareableLinkComponent/Icons/copy_link_icon.svg";
//import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
import ShowPdf from "components/SetsView/ShowPdf";
function FolderFilesDataTable({ ...props }) {
    const { foldersExpand, selectedNode, confirmDialog, setConfirmDialog, tokenInfo, _getFolderApi, setFolderTreeOnView, activeTab, inputRef, inputDir, allData,
        redirectId,
        setRedirectId,
        viewingIssueId,
        setViewingIssue } = props;
    //const webViewerInstance = getInstance();
    const [currentFolderId, setCurrentFolderId] = useState();
    const [currentActiveTab, setCurrentActiveTab] = useState(activeTab);
    const [itemRestored, setItemRestored] = useState(false);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [selectedFileRows, setSelectedFileRows] = useState([]);
    const [selectedFolderRows, setSelectedFolderRows] = useState([]);
    const [totalFilterApplied, setTotalFilterApplied] = useState(false);
    const [filterOptionsVisible, showFilterOptions] = useState(false);
    const [folderDataForSearch, setFolderDataForSearch] = useState();
    const [updatedByActiveFilters, setUpdatedByActiveFilters] = useState([]);
    const [fileTypeActiveFilters, setFileTypeActiveFilters] = useState([]);
    const [versionActiveFilters, setVersionActiveFilters] = useState([]);
    const [isGridView, setIsGridView] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [moveDialog, setMoveDialog] = useState();
    const [folderName, setFolderName] = useState(null);
    const [toastMessage, setToastMessage] = useState();
    const [currentTableRows, setTableRows] = useState([]);
    const currentTableRowData = useRef([]);
    const [credential, setCredential] = useState(tokenInfo);
    const [viewTableData, setViewTableData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState([]);
    const [filteredTableData, setFilteredTableData] = useState([]);
    const [currentRowId, setCurrentRowId] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedMenuRow, setSelectedMenuRow] = useState();
    const [deleteDialogData, setDeleteDialogData] = useState();
    const [renameDialogData, setRenameDialogData] = useState();
    const [uploadQueueFiles, setUploadQueueFilesState] = useState([]);
    const [uploadQueueFolders, setUploadQueueFoldersState] = useState([]);
    const [openCancleUpload, setOpenCancleUpload] = useState(false);
    const [attachmentThumbnail, setAttachmentThumbnail] = useState(null);
    const [pdfDocs, setPdfDocs] = useState([]);
    const [showFailedWarning, setShowFailedWarning] = useState(false);
    const [activeFilterDropdown, setActiveFilterDropdown] = useState(false);
    const [reviewStageOptions, setReviewStageOptions] = useState([]);
    const [toShareItems, setShareItems] = useState([]);
    const [fileTypeOptions, setFileTypeOptions] = useState([]);
    const [versionOptions, setVersionOptions] = useState([]);
    const [reviewStatusActiveFilters, setReviewStatusActiveFilters] = useState([]);
    const [openpopup, setOpen] = useState(false);
    const [pdfopenfiledata, setFileData] = useState();
    const processTofilter_ = useRef(null);
    const openMenu = Boolean(anchorEl);
    const errorMessage = "No available file/folder";
    const searchErrorMessage = "None of your files or folders matched this search";
    const confirmDialogData = { title: 'New Folder', content: 'Folder Name', action1: "Cancel", action2: "Add New Folder" };
    const [actionCalledFor, setActionCalledFor] = useState();
    const [searchText, setSearchText] = useState("");
    const [contentSearchRunning, isContentSearchRunning] = useState(false);
    let stopPDFInContentSearch = useRef(null);
    const [searchBoxFilter, setSearchBoxFilter] = useState(false);
    const [activeExportDialog, setActiveExportDialog] = useState(false);
    const [dragging, setDragging] = useState(false);
    const instanceRef = useRef(null);
    const pdfContainerRef = useRef(null);
    const pdfContainerRef1 = useRef(null);
    let stopBreakThumbmaking = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [exportIndicator, setExportIndicator] = useState(false);
    const [totalChild, setTotalChild] = useState();
    const [bgProcessItems, setBgProcessItems] = useState([]);
    let count = 0;
    //console.log("selectedNode ::", selectedNode);
    const files_folder_table = [
        {
            id: "checkbox",
            accessor: "",
            disableSortBy: true,
            Header: (
                <Checkbox
                    style={{ padding: "0px", marginLeft: "10px" }}
                    sx={{ padding: "0px", "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" } }}
                    checked={selectedRowIds.length > 0 && selectedRowIds.length === rowsPerPage?.length}
                    onChange={(e) => {
                        onRowSelectionAllChange(e.target.checked);
                    }}
                />
            ),
            Cell: (rowInfo) => {
                let fileData = selectedFileRows.filter(
                    (row) => row.id === rowInfo?.row?.original?.id
                );

                let folderData = selectedFolderRows.filter(
                    (row) => row.id === rowInfo?.row?.original?.id
                );
                return (
                    <Checkbox
                        style={{ padding: "0px", marginLeft: "10px" }}
                        sx={{ "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" } }}
                        type="checkbox"
                        className="checkbox"
                        checked={fileData.length > 0 || folderData.length > 0}
                        onChange={(e) =>
                            onRowSelectionChange(rowInfo?.row?.original?.id, e.target.checked)
                        }
                    />
                );
            },
            overrideStyle: { width: "7%" }
        },
        {
            accessor: "name",
            Header: "Name",
            sortType: "basic",
            className: "hide-menu-dot",
            Cell: (params_) => {
                let params = { id: params_.row.original.id, row: params_.row.original };
                //console.log("params.row.name ::", params.row);
                return (
                    <div style={{ display: 'flex' , width: "100%" ,gap:'10px' , alignItems : 'center'}} onClick={() => { params.row.type === "folder" ? setFolderTreeOnView({ id: params.id }) : fileNameClick(params.row); }}>
                        <Tooltip title={params.row.tooltip} placement="bottom">
                            <div style={{ color: "#505050", cursor: 'pointer', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '90%'}} 
                                onMouseDown={(e) => handleAuxClick(e, params.row.inappurl)} >
                                <img style={{ marginRight: "6px" }} src={params.row.type === "folder" ? folderIcon : pdfRed} alt="Settig" />
                                <span dangerouslySetInnerHTML={{ __html: params.row.name_mark ? params.row.name_mark : params.row.name }} />
                            </div>
                        </Tooltip>
                        <div style={{ marginLeft: 'auto' , display: "flex" }}>
                            {params.row.in_content_found && searchBoxFilter &&
                                <Tooltip title={"The file contains text from the search results"}>
                                    <Tooltip title={"The file contains text from the search results"} placement="bottom" >
                                        <img style={{ marginRight: "6px" }} src="/index search.svg" alt="Settig" />
                                    </Tooltip>
                                </Tooltip>
                            }
                            {params.row.Shared_Links__r?.length > 0  &&
                                <Tooltip title="Document is being shared" placement="bottom" >
                                    <div style={{ marginLeft: 'auto' }} onClick={(e)=>{
                                        e.stopPropagation();
                                        setShareItems([params.row]);
                                    }}>
                                        <img style={{ marginRight: "6px" }} src={copyLinkIcon} alt="copy" />
                                    </div>
                                </Tooltip>
                            }
                        </div>
                    </div>
                )
            },
            overrideStyle: { width: "25%" }
        },
        {
            accessor: "version",
            Header: "Version",
            disableSortBy: true,
            Cell: (rowInfo) => {
                return (<>
                    {rowInfo.row.original.type === "file" &&
                        <div style={{ background: "#EDF2FF", border: "1px solid #CCDBFF", color: "#346EF5", width: "20px", paddingLeft: "6px" }}>
                            <div style={{ color: "#346EF5" }}>{rowInfo.value}</div>
                        </div>
                    }
                </>
                );
            },
            overrideStyle: { width: "15%" }
        },
        {
            accessor: "markupCount",
            Header: "Markup",
            flex: 1,
            disableSortBy: true,
            Cell: (rowInfo) => {
                return (
                    <>
                        {rowInfo.row.original.type === "file" &&
                            <div>
                                <img style={{ marginRight: "6px" }} src={"/markup.svg"} alt="Settig" />
                                {rowInfo.value}
                            </div>
                        }
                    </>
                );
            },
            overrideStyle: { width: "15%" }
        },
        {
            accessor: "issue",
            Header: "Issue",
            width: "15%",
            flex: 1,
            disableSortBy: true,
            Cell: (rowInfo) => {
                return (
                    <>
                        {rowInfo.row.original.type === "file" &&
                            <div>
                                <img style={{ marginRight: "6px" }} src={"/error.svg"} alt="Settig" />
                                {0}
                            </div>
                        }
                    </>
                );
            },
            overrideStyle: { width: "15%" }
        },
        // {
        //     accessor: "reviewStatus",
        //     Header: "Review status",
        //     sortType: "basic",
        //     overrideStyle: { width: "15%" }
        // },

        {
            accessor: "updatedBy",
            Header: "Updated By",
            flex: 1,
            sortType: "basic",
            overrideStyle: { width: "20%" }
        },
        {
            accessor: "lastUpdated",
            Header: " Last Updated",
            flex: 1,
            sortType: "basic",
            overrideStyle: { width: "20%" }
        },
        {
            accessor: "settings",
            Header: "settings",
            flex: 1,
            disableSortBy: true,
            overrideStyle: { width: "5%" }
        },
    ];
    useEffect(() => {
        //console.log('fol files :: web instance :: ', webViewerInstance);
        if (totalFilterApplied) {
            //console.log("running 1");
            onHandleFilterData();
        }
    }, [folderDataForSearch]);
    //console.log('selectedNode?.length :: ',selectedNode?.length , selectedNode);
    useEffect(() => {
        if (props?.currentFolderName?.id) {
            let folderChange = currentFolderId !== props?.currentFolderName?.id;
            let tabSwitch = currentActiveTab !== activeTab;
            let refresh = (folderChange || (tabSwitch && activeTab === 0 && itemRestored));
            if (folderChange) {
                //console.log('folderChange : in',props?.currentFolderName?.id);
                setCurrentFolderId(props?.currentFolderName.id);
            }
            if (tabSwitch) {
                //console.log('tabSwitch : in',activeTab);
                setCurrentActiveTab(activeTab);
            }
            if (refresh) {
                if (itemRestored) {
                    setItemRestored(false);
                }
                //console.log('FolderFilesDataTable : in');
                if (tabSwitch && activeTab === 0) {
                    _getFolderApi({ id: props?.currentFolderName?.id });
                }
                refreshFolderFiles(props?.currentFolderName.id);
            }
            getAttachmentFilterOptions();
        }
    }, [props?.currentFolderName?.id, activeTab]);
    useEffect(() => {
        let cc = 0;
        if (reviewStatusActiveFilters.length > 0) {
            cc += reviewStatusActiveFilters.length;
        }
        if (updatedByActiveFilters.length > 0) {
            cc += updatedByActiveFilters.length;
        }
        if (fileTypeActiveFilters.length > 0) {
            cc += fileTypeActiveFilters.length;
        }
        if (versionActiveFilters.length > 0) {
            cc += versionActiveFilters.length;
        }
        setTotalFilterApplied(cc);
        if (processTofilter_.current) {
            console.log("running 2::")
            onHandleFilterData(cc);
        }
        processTofilter_.current = false;

    }, [reviewStatusActiveFilters, updatedByActiveFilters, fileTypeActiveFilters, versionActiveFilters]);
    useEffect(() => {
        if (!instanceRef.current) {
            setTimeout(() => {
                loadSampleDocument();
            }, 500);
        }
    },[]);
    useEffect(() => {
        if(redirectId){
            async function openDocument(){
                setLoading(true);
                console.log('in redirectId ',redirectId);
                let attach = await getAttachment(redirectId);
                //console.log('attach ',attach);
                setRedirectId(null);
                if(attach){
                    console.log('in attach found');
                    let prepareRow = await prepareAmazonRecordRowForProjectFile(attach , credential);
                    fileNameClick(prepareRow);
                }
                setLoading(false);
            }
            openDocument();
        }
    }, [redirectId]);

    function setInstance_(values) {
        instanceRef.current = values;
    }
    async function loadSampleDocument() {
        //console.log('pdfContainerRef.current :: ',pdfContainerRef.current);
        if (pdfContainerRef.current && !instanceRef.current) {
            await WebViewer({
                fullAPI: true,
                path: '/webviewer/public',
                licenseKey: APRYSE_L_KEY  // sign up to get a free trial key at https://dev.apryse.com,
            }, pdfContainerRef.current).then(async (instance) => {
                setInstance_(instance);
                console.log('instance :: ', instance);
            });
        }
    }

    async function loadDownloadDocument(fileData) {
        return new Promise(async (resolve, reject) => {
            console.log("fileData ::", fileData);
    
            if (pdfContainerRef1.current) {
                try {
                    const instance = await WebViewer({
                        fullAPI: true,
                        path: '/webviewer/public',
                        initialDoc: fileData.url,
                        licenseKey: APRYSE_L_KEY
                    }, pdfContainerRef1.current);
    
                    const { documentViewer, annotationManager } = instance.Core;
    
                    documentViewer.addEventListener('documentLoaded', async () => {
                        console.log("document loaded");
                        const doc = documentViewer.getDocument();
                        const xfdfString2 = fileData.Markup_Json__c;
                        await annotationManager.importAnnotations(xfdfString2);
                        doc.getPageCount();
                        const xfdfString = selectedFiles[0].Markup_Json__c;
                        const data = await doc.getFileData({  });
                        const arr = new Uint8Array(data);
                        const blob = new Blob([arr], { type: 'application/pdf' });
                        instance.UI.closeDocument().then(async function () {
                            console.log("return: ");
                        resolve(blob);
                        });
                        // window.saveAs(blob, selectedFiles[0].name); // Move this line if needed
                    });
                } catch (error) {
                    reject(error);
                }
            }
        });
    }
    const handleDragOver = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        setDragging(false);
        const items = e.dataTransfer.items;
        let root = { folderName: props?.currentFolderName?.name, subFolders: [], files: [] };
        await Promise.all(
            Array.from(items).map(async (item) => {
                if (item.kind === 'file') {
                    const entry = item.webkitGetAsEntry();
                    await processDropedEntry(entry, root);
                }
            })
        );
        //console.log('pre root ', JSON.stringify(root));
        if (root?.files?.length > 0) {
            uploadMedia(root?.files);
        }
        if (root?.subFolders?.length > 0) {
            processFolderToUpload(root?.subFolders);
        }
    };
    const processDropedEntry = async (entry, currentFolder) => {
        if (entry.isFile) {
            return new Promise((resolve) => {
                entry.file((file) => {
                    //currentFolder.files.push({ name: file.name, isFile: true, file: file });
                    currentFolder.files.push(file);
                    resolve();
                });
            });
        } else if (entry.isDirectory) {
            const reader = entry.createReader();
            const nextFolder = { folderName: entry.name, files: [], subFolders: [] };
            return new Promise(async (resolve) => {
                const readEntries = async () => {
                    const entries = await new Promise((resolve) => reader.readEntries(resolve));
                    if (entries.length > 0) {
                        await Promise.all(entries.map((subEntry) => processDropedEntry(subEntry, nextFolder)));
                        await readEntries();
                    } else {
                        currentFolder.subFolders.push(nextFolder);
                        resolve();
                    }
                };
                await readEntries();
            });
        }
    };
    const fileNameClick = (rowData) => {
        if (rowData.type === "file") {
            console.log('in file open');
            setFileData(rowData);
            handleClickOpen();
        } else {
            setFolderTreeOnView({ id: rowData.id })
        }
    };
    const handleClickOpen = () => {
        //console.log("setopen true " + pdfopenfiledata?.url)
        setOpen(true);
    };
    async function getAttachmentFilterOptions(_credential = credential) {
        const instanceUrl = _credential.instanceUrl;
        const token = _credential.token;
        /* if (!reviewStageOptions || reviewStageOptions?.length === 0) {
            var rewValues = [];
            var req_body = { fields: ['Stage__c'] };
            axios.post(`${fetchUrl}/picklist/valueObj/Review__c?token=${token}&instanceUrl=${instanceUrl}`, req_body)
            .then((res) => {
                if (res?.status === 200) {
                    let retData = res.data;
                    if (retData?.pickObj?.Stage__c?.valueList) {
                        rewValues = retData?.pickObj?.Stage__c?.valueList;
                    }
                }
                setReviewStageOptions(rewValues);
            }).catch((err) => {
                console.log(err);
                setReviewStageOptions(rewValues);
            });
        } */
    }
    async function onHandleFilterData(_totalFilterApplied = totalFilterApplied) {
        //console.log("running ::");
        let filteredFoundList = [];
        let orgData = cloneDeep(currentTableRowData.current);
        if (orgData?.length > 0 && _totalFilterApplied > 0) {
            for (const currRow of orgData) {
                let matched = true;
                if (reviewStatusActiveFilters?.length > 0 && matched === true) {
                    matched = false;
                    if (currRow.reviewStatus) {
                        matched = reviewStatusActiveFilters.includes(currRow.reviewStatus);
                    }
                }
                if (fileTypeActiveFilters?.length > 0 && matched === true) {
                    matched = false;
                    if (currRow.File_Extension__c || currRow.type) {
                        matched = fileTypeActiveFilters.includes(currRow.File_Extension__c || currRow.type);
                    }
                }
                if (versionActiveFilters?.length > 0 && matched === true) {
                    matched = false;
                    if (currRow.version) {
                        matched = versionActiveFilters.includes(currRow.version);
                    }
                }
                if (updatedByActiveFilters?.length > 0 && matched === true) {
                    matched = false;
                    const valueFound = updatedByActiveFilters.find(usss => usss.value === currRow.updatedById);
                    if (valueFound) {
                        matched = true;
                    }
                }
                if (matched) {
                    filteredFoundList.push(currRow);
                }
            }
        } else {
            filteredFoundList = cloneDeep(currentTableRowData.current);
        }
        //console.log('onHandleFilterData filteredFoundList :: '+filteredFoundList?.length);
        setFilteredTableData(filteredFoundList);
        setViewTableData(filteredFoundList);
    };
    async function searchListViewFunction(searchTerm) {
        setSearchBoxFilter(false);
        stopPDFInContentSearch.current = true;
        isContentSearchRunning(false);
        setSearchText(searchTerm);
        let orgData = (filteredTableData?.length > 0 || totalFilterApplied > 0) ? cloneDeep(filteredTableData) : cloneDeep(folderDataForSearch);
        //console.log(searchTerm,'orgData :: '+orgData?.length);
        let searchFoundList = cloneDeep(currentTableRowData.current);
        if (orgData?.length > 0) {
            if (searchTerm && searchTerm !== null && searchTerm !== "") {
                searchFoundList = [];
                let pdfDocs_ = [];
                for (let el of orgData) {
                    let fileExtension = el.fileExt;
                    if (el.type == 'file' && fileExtension && fileExtension.includes('pdf')) {
                        pdfDocs_.push(el);
                    }
                    if ((el.name && el.name.toLowerCase().includes(searchTerm)) || (el.title && el.title.toLowerCase().includes(searchTerm))) {
                        if (el.name && el.name.toLowerCase().includes(searchTerm)) {
                            el.name_mark = highlightText(el.name, searchTerm);
                        }
                        if (el.title && el.title.toLowerCase().includes(searchTerm)) {
                            el.title_mark = highlightText(el.title, searchTerm);
                        }
                        searchFoundList.push(el);
                    }
                }
                setSearchBoxFilter(true);
                setTimeout(() => {
                    if (pdfDocs_?.length > 0) {
                        stopPDFInContentSearch.current = false;
                        //initiateInContentSearch(cloneDeep(pdfDocs_), searchTerm);
                    }
                }, 100);
            }
        }
       //console.log(searchTerm, 'searchFoundList :: ' + searchFoundList?.length);
        setViewTableData(searchFoundList);
    };
    const onRowSelectionAllChange = (isChecked) => {
        setSelectedRowIds([]);
        let rowIds = [];
        if (isChecked === true) {
            //console.log('rowsPerPage :: ',rowsPerPage?.length);
            rowsPerPage.map((row) => rowIds?.push(row.id));
        } else {
            setSelectedRowIds([]);
        }
        setSelectedRowIds(rowIds);
        const selRowIds = new Set(rowIds);
        const seleFolRows = viewTableData.filter((row) =>
            selRowIds.has(row.id.toString()) && row.type === 'folder'
        );
        //console.log("seleFolRows ::", seleFolRows);
        setSelectedFolderRows(seleFolRows);
        const seleFileRows = viewTableData.filter((row) =>
            selRowIds.has(row.id.toString()) && row.type === 'file'
        );
        //console.log("selectedFileRows ::", seleFileRows);
        const folderFiles = [...seleFolRows, ...seleFileRows];
        setSelectedFileRows(seleFileRows);
        setSelectedFiles(folderFiles);
    }
    const onRowSelectionChange = (rowId, isChecked) => {
        //console.log("rowId ::", rowId);
        let rowIds = [];
        if (isChecked === true) {
            rowIds = selectedRowIds.length > 0 ? selectedRowIds : [];
            rowIds?.push(rowId);
        } else {
            rowIds = selectedRowIds.length > 0 ? selectedRowIds.filter((item) => item !== rowId) : [];
        }
        setSelectedRowIds(rowIds);
        const selRowIds = new Set(rowIds);
        const seleFolRows = viewTableData.filter((row) =>
            selRowIds.has(row.id.toString()) && row.type === 'folder'
        );
        //console.log("seleFolRows ::", seleFolRows);
        setSelectedFolderRows(seleFolRows);
        setSelectedFiles(seleFolRows);
        const seleFileRows = viewTableData.filter((row) =>
            selRowIds.has(row.id.toString()) && row.type === 'file'
        );
        //console.log("selectedFileRows ::", seleFileRows);
        const folderFiles = [...seleFolRows, ...seleFileRows];
        setSelectedFiles(folderFiles);
        setSelectedFileRows(seleFileRows);
    };
    async function refreshFolderFiles(id = props?.currentFolderName.id) {
        setSelectedRowIds([]);
        setCurrentFolderTableData([], true);
        setFolderDataForSearch([]);
        setSelectedFolderRows([]);
        setSelectedFileRows([]);
        setPdfDocs([]);
        generatePdfThumbnailMapPromise([]);
        await _getFolderFileApi(id, "refresh folder files");
    }
    async function _getFolderFileApi(id, calledFrom) {
        setActiveFilterDropdown(undefined);
        if (!moveDialog) {
            setLoading(true);
        }
        let subFolders = [];
        let subFiles = [];
        let fileType = [];
        let versionList = [];
        await axios.get(`${fetchUrl}/folder/${id}`).then(async (res) => {
            if (res?.status === 200) {
                // setIsLoading(false);
                const { files, sub_folders } = res?.data;
                //generatePdfThumbnailMapPromise(files);
                let withfile = [];
                sub_folders?.map(async (fol) => {
                    let folRow = await prepareFolderRecordRow(fol);
                    if (!fileType.includes("folder")) {
                        fileType.push("folder");
                    }
                    subFolders.push(folRow);
                    withfile.push(folRow);
                });
                files?.map(async (file) => {
                    let fileRow = await prepareAmazonRecordRowForProjectFile(file, credential);
                    let fileExtension = fileRow.fileExt;
                    let version = fileRow.version
                    if (!fileType.includes(fileExtension)) {
                        fileType.push(fileExtension);
                    }
                    if (!versionList.includes(version)) {
                        versionList.push(version);
                    }
                    const sortedNumbers = versionList.sort((a, b) => a - b);
                    setVersionOptions(sortedNumbers);
                    setFileTypeOptions(fileType);
                    if (attachmentThumbnail && attachmentThumbnail[fileRow.id]) {
                        fileRow.thumbnail = attachmentThumbnail[fileRow.id];
                    }
                    subFiles.push(fileRow)
                    withfile.push(fileRow);
                });
                setFolderDataForSearch(withfile); // init folder files for view
                setCurrentFolderTableData(withfile, true);
            }
        }).catch((err) => {
            console.log("/folder_/", err);
        });
        setLoading(false);
        if (true) {
            let pdfFiles = [];
            axios.get(`${fetchUrl}/folderfiles/${id}`).then(async (res) => {
                if (res?.status === 200) {
                    const { files, folders } = res?.data;
                    let withfile = [];
                    folders?.map(async (fol) => {
                        let folRow = await prepareFolderRecordRow(fol);
                        withfile.push(folRow);
                    });
                    files?.map(async (file) => {
                        let fileRow = await prepareAmazonRecordRowForProjectFile(file, credential);
                        let fileExtension = fileRow.fileExt;
                        //console.log('fileExtension :: ',fileExtension);
                        if (fileExtension && fileExtension.toLowerCase().includes('pdf')) {
                            pdfFiles.push(fileRow);
                        }
                        withfile.push(fileRow);
                    });
                    setFolderDataForSearch(withfile);
                    setPdfDocs(pdfFiles);
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }
    const generatePdfThumbnailMapPromise = async (attachmentRows) => {
        const BATCH_SIZE = 10;
        let promises = [];
        if (attachmentRows?.length > 0) {
          stopBreakThumbmaking.current = false;
          for (let i = 0; i < attachmentRows.length; i++) {
            if (stopBreakThumbmaking.current) break;
            const file = attachmentRows[i];
            if (file.url && (!attachmentThumbnail || !attachmentThumbnail[file.id]) && file.File_Extension__c?.includes('pdf')) {
              promises.push(genratePdfThumnail(file.id, file.url));
            }
            if (promises.length === BATCH_SIZE || i === attachmentRows.length - 1) {
              if(promises?.length > 0){
                //console.time(i,'promise starts :: ',promises?.length);
                await Promise.all(promises).then((values) => {
                  //console.timeEnd(i,'promise starts :: ',values?.length);
                    if (values?.length > 0) {
                        let temList_folFiles = JSON.parse(JSON.stringify(currentTableRowData.current)); let curfolFilePush = false;
                        let temList_viewFiles = JSON.parse(JSON.stringify(viewTableData)); let viewFilePush = false;
                        let temList_filFiles = JSON.parse(JSON.stringify(filteredTableData)); let curFilFilePush = false;
                        for (let pdfth of values) {
                            if (pdfth !== null && pdfth.attachId && pdfth.thumbnail) {
                                let atchId = pdfth.attachId;
                                let thumbnail = pdfth.thumbnail;
                                if (temList_folFiles?.length > 0) {
                                    const fInd = temList_folFiles?.findIndex(
                                        (data) => data.id === atchId
                                    );
                                    if (fInd > -1) {
                                        curfolFilePush = true;
                                        temList_folFiles[fInd].thumbnail = thumbnail;
                                    }
                                }
                                if (temList_viewFiles?.length > 0) {
                                    const fInd = temList_viewFiles?.findIndex(
                                        (data) => data.id === atchId
                                    );
                                    if (fInd > -1) {
                                        viewFilePush = true;
                                        temList_viewFiles[fInd].thumbnail = thumbnail;
                                    }
                                }
                                if (temList_filFiles?.length > 0) {
                                    const fInd = temList_filFiles?.findIndex(
                                        (data) => data.id === atchId
                                    );
                                    if (fInd > -1) {
                                        curFilFilePush = true;
                                        temList_filFiles[fInd].thumbnail = thumbnail;
                                    }
                                }
                            }
                        }
                        if (curfolFilePush) {
                            setCurrentFolderTableData(temList_folFiles);
                            setFolderDataForSearch(temList_folFiles);
                        }
                        if (curFilFilePush) {
                            setFilteredTableData(temList_filFiles);
                        }
                    }
                }).catch(err=>{
                  console.log('promise all',err);
                });
              }
              promises = [];
            }
          }
        } else {
          stopBreakThumbmaking.current = true;
        }
    }
    const genratePdfThumnail = async (attachId, fileUrl, updateList) => {
        let pdfThumbnail = await getPdfThumbnailFromWebViewer(attachId, fileUrl, instanceRef.current);
        if (pdfThumbnail && pdfThumbnail.attachId && pdfThumbnail.thumbnail) {
            let imgthumEleId = pdfThumbnail.attachId + '_thumb_img';
            setAttachmentThumbnail((preMap) => {
                if (!preMap) {
                    preMap = {};
                }
                preMap[pdfThumbnail.attachId] = pdfThumbnail.thumbnail;
                var imgEle = document.getElementById(imgthumEleId);
                if (imgEle) {
                    imgEle.src = pdfThumbnail.thumbnail;
                    imgEle.style = {};
                }
                //console.log('gen sse :: ',pdfThumbnail.thumbnail);
                return preMap;
            });
            if (updateList === true) {
                updateThumbnailToCurrentData(pdfThumbnail.attachId, pdfThumbnail.thumbnail);
            }
            return pdfThumbnail;
        }
        return null;
    }
    const updateThumbnailToCurrentData = (attachId, thumUrl) => {
        
        //setSpinner(true);
        if (attachId) {
            toUpdateElementbyId(attachId, thumUrl, currentTableRowData.current, setCurrentFolderTableData);
            toUpdateElementbyId(attachId, thumUrl, viewTableData, setViewTableData);
            toUpdateElementbyId(attachId, thumUrl, filteredTableData, setFilteredTableData);
            toUpdateElementbyId(attachId, thumUrl, folderDataForSearch, setFolderDataForSearch);
            function toUpdateElementbyId(attchId, thubUrl, _list, setToList) {
                let _tempList = cloneDeep(_list);
                if (_tempList && _tempList?.length > 0) {
                    const fInd = _tempList?.findIndex(
                        (data) => data.id === attchId
                    );
                    if (fInd > -1) {
                        _tempList[fInd].thumbnail = thubUrl;
                        if (setToList) {
                            setToList(_tempList);
                        }
                    }
                }
                return _tempList;
            }
        }
        //setSpinner(false);
    };
    const initiateInContentSearch = async (attachmentRows, searchText) => {
        const promises = [];
        if (attachmentRows?.length > 0) {
            isContentSearchRunning(true);
            stopPDFInContentSearch.current = false;
            for (let file of attachmentRows) {
                if (stopPDFInContentSearch.current === true) {
                    break;
                }
                let promise = await searchInPdfContent(file, searchText, file.url);
                promises.push(promise);
                if (stopPDFInContentSearch.current === true) {
                    break;
                }
            }
        } else {
            stopPDFInContentSearch.current = true;
        }
        if (promises?.length > 0) {
            Promise.all(promises).then((values) => {
                //console.log('values :: ',values?.length);
                isContentSearchRunning(false);
            });
        } else {
            isContentSearchRunning(false);
        }
    }
    const searchInPdfContent = async (file_, searchTerm, fileUrl) => {
        const file = cloneDeep(file_);
        let pdfThumbnail = await searchTextInPdfContentWebViewer(file.id, searchTerm, fileUrl, instanceRef.current);
        if (pdfThumbnail && pdfThumbnail.attachId && pdfThumbnail.found) {
            setViewTableData((preList) => {
                if (!preList) {
                    preList = [];
                }
                const fInd = preList?.findIndex(
                    (data) => data.id === file.id
                );
                file.in_content_found = true;
                if (fInd > -1) {
                    preList[fInd] = file;
                } else {
                    preList.push(file);
                }
                return preList;
            });
            return pdfThumbnail;
        }
        return null;
    }
    function setCurrentFolderTableData(filesData, setInView) {
        currentTableRowData.current = filesData;
        setTableRows(filesData);
        if (setInView === true) {
            setViewTableData(filesData);
        }
        return filesData;
    }
    const handleCloseMenu = () => {
        setAnchorEl(null);
        setSelectedMenuRow(null);
    };
    const handleClickMenu = (event, parms) => {
        event.preventDefault();
        event.stopPropagation();
        setSelectedMenuRow(parms.row);
        setSelectedFiles([parms.row]);
        setAnchorEl(event.currentTarget);
    };
    async function saveFolder(folderName) {
        setFolderName(folderName);
        const reqBody = { Name: folderName };
        setConfirmDialog(null);
        setLoading(true);
        await axios.post(`${fetchUrl}/folder/${props?.currentFolderName?.id}/subfolder/add/?accessToken=${tokenInfo?.token}&instanceUrl=${tokenInfo?.instanceUrl}`, reqBody)
            .then(async (res) => {
                if (res?.status === 200) {
                    //console.log('res :: '+JSON.stringify(res));
                    _getFolderApi({ id: res.data?.Parent_Folder__c });
                    refreshFolderFiles(props?.currentFolderName?.id);
                    setToastMessage(`${folderName} has been added`);
                } else {
                    setLoading(false);
                }
            }).catch(err => {
                setLoading(false);
                console.error(err);
            });
    }
    async function onClickOpenAction(action, clickSource) {
        let dialogData;
        let typeToLabel = { 'file': 'File', 'folder': 'Folder', 'item': 'Item' }
        if (clickSource === 'header') {
            setAnchorEl(false);
        }
        if (action === 'delete') {
            if (clickSource === 'menu') {
                if (selectedMenuRow) {
                    if (action === 'delete') {
                        dialogData = {
                            title: `Delete ${typeToLabel[selectedMenuRow.type]}`,
                            content: `Are you sure you want to delete the selected ${selectedMenuRow.type}? The deleted ${selectedMenuRow.type} can be recovered in the Deleted Files tab.`,
                            action1: "Cancel",
                            action2: "Delete"
                        };
                    }
                }
            } else if (clickSource === 'header') {
                if (selectedRowIds) {
                    let type = selectedFileRows?.length > 0 && selectedFolderRows?.length > 0 ? 'item' :
                        selectedFolderRows?.length > 0 ? 'folder' : selectedFileRows?.length > 0 && 'file';
                    let multi = selectedRowIds?.length > 1;
                    if (action === 'delete') {
                        dialogData = {
                            title: `Delete ${typeToLabel[type]}${multi ? 's' : ''}`,
                            content: `Are you sure you want to delete the selected ${type}${multi ? 's' : ''}? The deleted ${type}${multi ? 's' : ''} can be recovered in the Deleted Files tab.`,
                            action1: "Cancel",
                            action2: "Delete"
                        };
                    }
                }
            }
            setDeleteDialogData(dialogData);
        } else if (action === 'rename') {
            var rowData = selectedMenuRow;
            if (clickSource === 'menu') {
                rowData = selectedMenuRow;
            } else if (clickSource === 'header') {
                rowData = selectedFolderRows?.length > 0 ? selectedFolderRows[0] : selectedFileRows?.length > 0 && selectedFileRows[0];
            }
            if (rowData) {
                dialogData = {
                    title: `Rename`,
                    content: `${typeToLabel[rowData.type]} Name`,
                    action1: "Cancel",
                    action2: "Save Changes",
                    errorMessage: `${typeToLabel[rowData.type]} name alreay exists. Please choose a new one.`,
                    rowData: rowData,
                    rowTitleChange: false

                };
                setAnchorEl(false);
                setConfirmDialog("rename");
                setRenameDialogData(dialogData);
            }
        } else if (action === 'move' || action === 'copy') {
            //console.log("selectedMenuRow ::", selectedMenuRow);
            let acToLabel = { 'move': 'Move', 'copy': 'Copy' }
            let moveItems = [];
            if (clickSource === 'menu') {
                moveItems.push(selectedMenuRow);
                dialogData = {
                    title: `${acToLabel[action]} "${selectedMenuRow.name}"`,
                    content: allData,
                    action1: "Cancel",
                    action2: `${acToLabel[action]}`,
                    label2: selectedMenuRow.name,
                }
                dialogData.moveItems = moveItems;
            } else if (clickSource === 'header') {
                moveItems = viewTableData.filter((row) => selectedRowIds.includes(row.id));
                let isMulti = moveItems?.length > 1;
                dialogData = {
                    title: `${acToLabel[action]} ${selectedRowIds.length} ${isMulti ? 'items' : 'item'}`,
                    content: allData,
                    action1: "Cancel",
                    action2: `${acToLabel[action]}`,
                    label2: `${isMulti ? 'Items' : 'Item'}`
                }
            }
            dialogData.clickSource = clickSource;
            dialogData.operation = action;
            dialogData.items = moveItems;
            setMoveDialog(dialogData);
        }
        if (clickSource === 'cancel') {
            dialogData = { title: 'Stop Uploads?', content: 'Are you sure you want to stop ongoing uploads? If you proceed, any ongoing uploads will be lost.', action1: "Cancel", action2: "Stop Uploads" };
            setAnchorEl(false);
            setDeleteDialogData(dialogData);
        }
    }
    const onClickDeleteItemSingle = async (restoreItem = selectedMenuRow) => {
        //console.log("onClickRestoreItemSingle_ restoreItem :: ", restoreItem);
        let deleteDialog_ = deleteDialogData;
        setDeleteDialogData(null);
        if (restoreItem) {
            //console.log('deleteDialog ::',deleteDialog_);
            setLoading(true);
            let toastMessage = `${restoreItem.name + ' ' + restoreItem.type} has been deleted.`;
            await axios.put(`${fetchUrl}/recycle/${restoreItem.type}/${restoreItem.id}`).then(res => {
                setToastMessage(toastMessage);
                if (props?.currentFolderName?.id) {
                    _getFolderApi({ id: props?.currentFolderName?.id });
                    refreshFolderFiles(props?.currentFolderName?.id);
                } else {
                    setLoading(false);
                }
            }).catch((err) => {
                console.log(err);
                setLoading(false);
            });
        }
    };
    const onClickDeleteItems = async (toDeleteIds = selectedRowIds) => {
        //console.log("onClickDeleteItems_ restoreItemIds :: ", toDeleteIds);
        setDeleteDialogData(null);
        if (toDeleteIds.length > 0) {
            setLoading(true);
            let typeToLabel = { 'file': 'File', 'folder': 'Folder', 'item': 'Item' }
            let type = selectedFileRows?.length > 0 && selectedFolderRows?.length > 0 ? 'item' :
                selectedFolderRows?.length > 0 ? 'folder' : selectedFileRows?.length > 0 && 'file';
            let multi = toDeleteIds?.length > 1;
            let iName = '';
            if (multi === false) {
                if (selectedFolderRows?.length > 0) {
                    iName = selectedFolderRows[0].name;
                } else {
                    iName = selectedFileRows[0].name
                }
            }
            let toDeleteCount = type === 'item' ? 2 : 1;
            let deletedCount = 0;
            if (selectedFileRows?.length > 0) {
                let toDeleteFiles = selectedFileRows?.map(obj => obj.id);
                let reqBody = { recIds: toDeleteFiles };
                await axios.post(`${fetchUrl}/recycle/list/file`, reqBody).then(res => {
                    deletedCount++;
                }).catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
            }
            if (selectedFolderRows?.length > 0) {
                let toDeleteFols = selectedFolderRows?.map(obj => obj.id);
                if (toDeleteFols?.length > 0) {
                    let reqBody = { recIds: toDeleteFols };
                    await axios.post(`${fetchUrl}/recycle/list/folder`, reqBody).then(res => {
                        deletedCount++;
                    }).catch((err) => {
                        console.log(err);
                        setLoading(false);
                    });
                }
            }
            if (toDeleteCount === deletedCount) {
                let toastMessage = multi ? `${toDeleteIds?.length} ${typeToLabel[type]}s have been deleted` :
                    `${iName + ' ' + type} has been deleted.`;
                setToastMessage(toastMessage);
                if (props?.currentFolderName?.id) {
                    _getFolderApi({ id: props?.currentFolderName?.id });
                    refreshFolderFiles(props?.currentFolderName?.id);
                    return;
                }
            } else {
                setLoading(false);
            }
        }
    };
    async function handleNewFolderErrorCheck(folderName) {
        let orgData = cloneDeep(viewTableData);
        //console.log("orgData ::", orgData);
        if (folderName && folderName != null && folderName !== "") {
            if (orgData && orgData?.length > 0) {
                for (let el of orgData) {
                    //console.log("count ::", count);
                    if ((el.name && el.name.toLowerCase().trim() === folderName.toLowerCase().trim())) {
                        setError(true);
                        return;
                    }
                }
            }
            setError(false);
            saveFolder(folderName.trim());
        }
    }
    const handleCloseIcon = () => {
        setSelectedFolderRows([]);
        setSelectedRowIds([]);
        setSelectedFileRows([]);
    };
    const uploadMedia = async (options) => {
        //console.log("sdsdsds", options);
        //setLoading(true);
        if (options?.length > 0 && options?.length <= 1000) {
            const fileToProcess = handlePrepareUploadQueue(options);
            const handleFileUploadProgress = (type, file) => {
                const curFile = _.find(fileToProcess, { id: file?.id });
                //console.log('handleFileUploadProgress ',curFile);
                if (curFile && !curFile.processed) {
                    if (type === "failed") {
                        curFile["failed"] = true;
                    } else if (type === "canceled") {
                        curFile["canceled"] = true;
                    } else if (type === "success") {
                        curFile["completed"] = true;
                    }
                    curFile["processed"] = true;
                    console.log('file?.name ', curFile?.fileName, 'type', type);
                    const remainData = _.reject(fileToProcess, curFile);
                    let toPutfiles = [...remainData, curFile];
                    setUploadQueueFilesState(toPutfiles);
                    let filtered = toPutfiles.filter((file) => file.processed === true);
                    if (filtered?.length === toPutfiles?.length) {
                        let toastMessage;
                        if (props?.currentFolderName?.id) {
                            refreshFolderFiles(props?.currentFolderName.id);
                            //setLoading(false);
                            toastMessage = "Ongoing uploads have been completed";
                        }
                        if (type === "canceled") {
                            setDeleteDialogData(null);
                            setUploadQueueFilesState([]);
                            toastMessage = "Ongoing uploads have been stopped";
                        }
                        if (toastMessage) {
                            setToastMessage(toastMessage);
                        }
                    }
                }
            };
            if (fileToProcess?.length > 0) {
                let changed = false;
                for (let f of fileToProcess) {
                    if (f.started === false) {
                        const fileData = { id: f.id, fileName: f.file.name, file: f.file };
                        const uploader = new Uploader(credential.projectId, props?.currentFolderName?.id, fileData);
                        uploader.start(handleFileUploadProgress);
                        f.uploader = uploader;
                        f.started = true;
                        changed = true;
                    }
                }
                if (changed) {
                    setUploadQueueFilesState(fileToProcess);
                }
            }
        }
    };
    const uploadFolder = async (folders) => {
        //console.log("uploadFolder", folders );
        if (folders.length > 0) {
            let retArray = createFolderStructure(folders);
            //console.log("retArray", retArray );
            processFolderToUpload(retArray);
        }
    }
    function createFolderStructure(folders) {
        const root = { name: '', files: [], subFolders: [] };
        for (let key = 0; key < folders.length; key++) {
            const file = folders[`${key}`];
            let relativePath = file.webkitRelativePath;
            const splitedPath = relativePath.split('/');
            let currentFolder = root;
            splitedPath.forEach((path, index) => {
                const isLastpath = index === splitedPath.length - 1;
                let currentItem;
                if (isLastpath) {
                    // This is a file
                    currentItem = { name: file.name, isFile: true, file: file };
                    currentFolder.files.push(file);
                } else {
                    // This is a folder
                    currentItem = currentFolder.subFolders.find((folder) => folder.folderName === path);
                    if (!currentItem) {
                        currentItem = { folderName: path, files: [], subFolders: [] };
                        currentFolder.subFolders.push(currentItem);
                    }
                }
                currentFolder = currentItem;
            });
        }
        return root.subFolders;
    }
    function handlePrepareUploadQueue(value) {
        //console.log('uploadQueueFiles :: ',JSON.stringify(uploadQueueFiles));
        let state = [...uploadQueueFiles];
        for (let f of value) {
            let identifier = makeRandomId(9);
            state = [...state, { id: identifier, label: f?.name, fileName: f?.name, file: f, started: false, isFile: true }];
        }
        setUploadQueueFilesState(state);
        return state;
    }
    const processFolderToUpload = async (folders) => {
        //console.log("root?.folders", JSON.stringify(folders) );
        const foldersToProcess = prepareFolderUploadQueue(folders);
        const handleFolderFileUploadProgress = (type, file) => {
            const curFolder = _.find(foldersToProcess, { id: file?.parentIdentifier });
            if (curFolder && !curFolder.processed) {
                const curFile = _.find(curFolder?.files, { id: file?.id });
                //console.log('handleFileUploadProgress ',curFile);
                if (curFile && !curFile.processed) {
                    if (type === "failed") {
                        curFile["failed"] = true;
                    } else if (type === "canceled") {
                        curFile["canceled"] = true;
                    } else if (type === "success") {
                        curFile["completed"] = true;
                    }
                    curFile["processed"] = true;
                    console.log('file?.name ', curFile?.label, 'type', type);
                    const remainFilesData = _.reject(curFolder?.files, curFile);
                    let toPutfiles = [...remainFilesData, curFile];
                    curFolder.files = toPutfiles;
                    let filtered = toPutfiles.filter((file) => file.processed === true);
                    curFolder.label = `${curFolder.folderName} (${filtered?.length} of ${toPutfiles?.length})`;
                    if (filtered?.length === toPutfiles?.length) {
                        if (type === "failed") {
                            curFolder["failed"] = true;
                        } else if (type === "canceled") {
                            curFolder["canceled"] = true;
                        } else if (type === "success") {
                            curFolder["completed"] = true;
                        }
                        curFolder["processed"] = true;
                        const remainFolsData = _.reject(foldersToProcess, curFile);
                        let toPutfols = [...remainFolsData, curFile];
                        setUploadQueueFoldersState(toPutfols);
                        let filteredd = toPutfols.filter((fol) => fol.processed === true);
                        if (filteredd?.length === toPutfols?.length) {
                            let toastMessage;
                            if (props?.currentFolderName?.id) {
                                _getFolderApi({ id: props?.currentFolderName.id });
                                refreshFolderFiles(props?.currentFolderName.id);
                                //setLoading(false);
                                toastMessage = "Ongoing uploads have been completed";
                            }
                            if (type === "canceled") {
                                setUploadQueueFoldersState([]);
                                setDeleteDialogData(null);
                                toastMessage = "Ongoing uploads have been stopped";
                            }
                            if (toastMessage) {
                                setToastMessage(toastMessage);
                            }
                        }
                    } else {
                        const remainFolsData = _.reject(curFolder?.files, curFile);
                        let toPutfols = [...remainFolsData, curFile];
                        setUploadQueueFoldersState(toPutfols);
                    }
                }
            }
        };
        if (foldersToProcess?.length > 0) {
            for (let fol of foldersToProcess) {
                if (fol.started === false) {
                    fol.started = true;
                    const reqBody = { Name: fol.folderName };
                    //console.log('fol.folderName :: ',fol.folderName);
                    axios.post(`${fetchUrl}/folder/${props?.currentFolderName?.id}/subfolder/add/?accessToken=${tokenInfo?.token}&instanceUrl=${tokenInfo?.instanceUrl}`, reqBody, {
                        cancelToken: fol.cancelToken,
                    }).then(async (res) => {
                        if (res?.status === 200) {
                            fol.sfId = res.data?.Id;
                            for (let f of fol.files) {
                                if (f.started === false) {
                                    const fileData = { id: f.id, fileName: f.file.name, file: f.file, parentIdentifier: fol.id };
                                    const uploader = new Uploader(credential.projectId, fol?.sfId, fileData);
                                    uploader.start(handleFolderFileUploadProgress);
                                    f.uploader = uploader;
                                    f.started = true;
                                }
                            }
                        }
                        //console.log('fol.folderName :: in ');
                        setUploadQueueFoldersState((preRows) => {
                            let tempFileSplitRows = cloneDeep(preRows);
                            let fnd = tempFileSplitRows?.findIndex(file => file.id === fol.id);
                            if (fnd > -1) {
                                tempFileSplitRows[fnd] = fol;
                            }
                            return tempFileSplitRows;
                        });
                    }).catch(err => {
                        console.log('folders err sf get', axios.isCancel(err));
                        if (axios.isCancel(err)) {
                            fol.canceled = true;
                        } else {
                            fol.failed = true;
                        }
                        fol.processed = true;
                        setUploadQueueFoldersState((preRows) => {
                            let tempFileSplitRows = cloneDeep(preRows);
                            let fnd = tempFileSplitRows?.findIndex(file => file.id === fol.id);
                            if (fnd > -1) {
                                tempFileSplitRows[fnd] = fol;
                            }
                            let filtered = tempFileSplitRows.filter((file) => file.processed === true);
                            if (filtered?.length === tempFileSplitRows?.length) {
                                if (axios.isCancel(err)) {
                                    setDeleteDialogData(null);
                                    let toastMessage = "Ongoing uploads have been stopped";
                                    setToastMessage(toastMessage);
                                    return [];
                                }
                            }
                            return tempFileSplitRows;
                        });
                    });
                }
            }
        }
    };
    const prepareFolderUploadQueue = (folders) => {
        let state = [...uploadQueueFolders];
        for (let fol of folders) {
            let identifier = makeRandomId(9);
            const { token, cancel } = axios.CancelToken.source();
            let folObj = {
                id: identifier, folderName: fol?.folderName, label: fol?.folderName,
                started: false,
                isFolder: true,
                'cancelToken': token,
                'cancelAction': cancel,
                files: []
            };
            if (fol?.files?.length > 0) {
                folObj.label = `${folObj.folderName} (0 of ${fol.files?.length})`;
                for (let f of fol?.files) {
                    let identifier = makeRandomId(9);
                    folObj.files.push({ id: identifier, label: f?.name, fileName: f?.name, file: f, started: false, isFile: true });
                }
                state.push(folObj);
            }
        }
        setUploadQueueFoldersState(state);
        return state;
    };
    const cancelUploads = async (toCancel) => {
        if (toCancel?.length > 0) {
            for (let f of toCancel) {
                const curFile = _.find(toCancel, { id: f?.id });
                if (curFile && curFile.started === true && !curFile.completed) {
                    const uploader = curFile.uploader;
                    uploader.abort();
                }
            }
        }
    };
    const cancelFolderUploads = async (toCancel) => {
        if (toCancel?.length > 0) {
            for (let f of toCancel) {
                const curFol = _.find(toCancel, { id: f?.id });
                //console.log('curFol.started', curFol.started, 'fl.processed', curFol.processed, 'fl.failed', curFol.failed, 'fl.sfId', curFol.sfId);
                if (curFol && (!curFol.sfId || curFol.started === true) && !curFol.completed) {
                    if (!curFol.sfId) {
                        if (curFol.cancelToken && curFol.cancelAction) {
                            curFol.cancelAction('Upload canceled by user.');
                        }
                    } else {
                        cancelUploads(f.files);
                    }
                }
            }
        }
    };
    function _handleCloseProcess(expanded, setExpand) {
        let actionType;
        let actionTypeFolder;
        if (uploadQueueFiles?.length > 0) {
            for (let fl of uploadQueueFiles) {
                if (fl.started === true && !fl.processed) {
                    actionType = 'open_cancel_dialog';
                    break;
                } else if (expanded === false && fl.failed === true) {
                    actionType = 'open_failed_warning';
                    break;
                } else {
                    actionType = 'clear_que';
                }
            }
        } else {
            actionType = 'clear_que';
        }
        if (uploadQueueFolders?.length > 0) {
            for (let fl of uploadQueueFolders) {
                //console.log('fl.started',fl.started,'fl.processed',fl.processed,'fl.failed',fl.failed);
                if (fl.started === true && !fl.processed) {
                    actionTypeFolder = 'open_cancel_dialog';
                    break;
                } else if (expanded === false && fl.failed === true) {
                    actionTypeFolder = 'open_failed_warning';
                    break;
                } else {
                    actionTypeFolder = 'clear_que';
                }
            }
        } else {
            actionTypeFolder = 'clear_que';
        }
        //console.log('actionType ',actionType,uploadQueueFiles.length);
        //console.log('actionTypeFolder ',actionTypeFolder,uploadQueueFolders.length);
        if (actionType === 'open_cancel_dialog' || actionTypeFolder === 'open_cancel_dialog') {
            if (uploadQueueFiles.length > 0 || uploadQueueFolders.length > 0) {
                onClickOpenAction('upload', 'cancel')
            }
        } else if (actionType === 'open_failed_warning' || actionTypeFolder === 'open_cancel_dialog') {
            setShowFailedWarning(true);
            if (setExpand) {
                setExpand(true);
            }
        } else {
            if (actionType === 'clear_que' && actionTypeFolder === 'clear_que') {
                setUploadQueueFilesState([]);
                setUploadQueueFoldersState([]);
            }
        }
    }
    const handleClearAllFilters = () => {
        setViewTableData(currentTableRows);
        setActiveFilterDropdown(undefined);
        setUpdatedByActiveFilters([]);
        setReviewStatusActiveFilters([]);
        setFileTypeActiveFilters([]);
        setVersionActiveFilters([]);
    };
    const updateStateTableDataWithRow = async (rowEle) => {
        
        // setSpinner(true);
        if (rowEle.id) {
            toReplaceElementbyId(rowEle, currentTableRowData.current, setCurrentFolderTableData);
            toReplaceElementbyId(rowEle, viewTableData, setViewTableData);
            toReplaceElementbyId(rowEle, filteredTableData, setFilteredTableData);
            toReplaceElementbyId(rowEle, folderDataForSearch, setFolderDataForSearch);

            function toReplaceElementbyId(ele, _list, setToList) {
                let _tempList = cloneDeep(_list);
                if (_tempList?.length > 0) {
                    const fInd = _tempList?.findIndex(
                        (data) => data.id === ele.id
                    );
                    if (fInd > -1) {
                        _tempList[fInd] = ele;
                        if (setToList) {
                            setToList(_tempList);
                        } // init folder files
                    }
                }
                return _tempList;
            }
        }
        //  setSpinner(false);
    };
    const updateTableStateForLink = (links) => {
      if (links?.length > 0) {
        setLoading(true);
        toAddLinksToElementbyId(links, currentTableRowData.current, setCurrentFolderTableData);
        toAddLinksToElementbyId(links, viewTableData, setViewTableData);
        toAddLinksToElementbyId(links, filteredTableData, setFilteredTableData);
        toAddLinksToElementbyId(links, folderDataForSearch, setFolderDataForSearch);
        setLoading(false);
      }
      function toAddLinksToElementbyId(links, _list, setToList) {
        let _tempList = cloneDeep(_list);
        if (_tempList?.length > 0) {
          let change = false;
          for (let lnk of links) {
            if (lnk.id) {
              let rowId = lnk.id;
              let link = lnk.link;
              const fInd = _tempList?.findIndex((data) => data.id === rowId);
              if (fInd > -1) {
                if(link){
                    if (!(_tempList[fInd].Shared_Links__r?.length > 0)) {
                      _tempList[fInd].Shared_Links__r = [link];
                      change = true;
                    }
                }else{
                    if ((_tempList[fInd].Shared_Links__r?.length > 0)) {
                      _tempList[fInd].Shared_Links__r = [];
                      change = true;
                    }
                }
              }
            }
          }
          if (change == true) {
            if (setToList) {
              setToList(_tempList);
            }
          }
        }
        return _tempList;
      }
    };
    const handleOpenExportDialog = (eType) => {
        setActionCalledFor(eType);
        setActiveExportDialog(true);
    };
    const handleCloseExportDialog = () => {
        setActiveExportDialog(false);
    };
    
const findFolderById = (folders, targetId) => {
    for (const folder of folders) {
      if (folder.value.Id === targetId) {
        return folder;
      }
      const foundInChildren = findFolderById(folder.children, targetId);
      if (foundInChildren) {
        return foundInChildren;
      }
    }
    return null;
};

const addFolderToZip = async (zip, folder , exportWithMarkup) => {
    const folderZip = zip.folder(folder.value.Name);
    const files = await fetchFilesForFolder(folder.value.Id);
    //console.log("files :::", files?.length);
    if(files?.length > 0 ){
        for (const file of files) {
            const fileBlob = await getFileBlob(file , exportWithMarkup);
            if(fileBlob){
                folderZip.file(`${file.File_Name__c}`, fileBlob);
            }
        }
    }
    if(folder.children?.length > 0 ){
        for (const subfolder of folder.children) {
          await addFolderToZip(folderZip, subfolder, exportWithMarkup);
        }
    }
  };

  const fetchFilesForFolder = async (folderId) => {
    try {
        // Replace this with your actual API endpoint for fetching files for a folder
        const response = await axios.get(`${fetchUrl}/folder/${folderId}`);
        if (response?.status === 200) {
            //console.log("res.data ::", response.data);
            return response.data.files;
        }
    } catch (error) {
        console.error(`Error fetching files for folder ${folderId}:`, error);
        return [];
    }
  };

    const handleDownloadFolderFiles = async (filesForDownload, fileName, exportWithMarkup) => {
        let failed = true;
        if(!fileName){
            fileName = generateDownloadFileName(filesForDownload);
        }
        //console.log('fileName :: ',fileName, filesForDownload?.length);
        const zip = new JSZip();
        for (const item of filesForDownload) {
            if(item.type == "file"){
                const fileBlob = await getFileBlob(item , exportWithMarkup);
                if(fileBlob){
                    zip.file(`${item.File_Name__c}`, fileBlob);
                }
            }else{
                const selectedFolder = findFolderById(allData[0].children, item.id); 
                if (!selectedFolder) {
                    console.error(`Folder with ID ${item.Id} not found.`);
                    continue;
                }
                await addFolderToZip(zip, selectedFolder, exportWithMarkup);
            }
        }
        await zip.generateAsync({ type: 'blob' }).then((content) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(content);
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            failed = false;
        });
        return failed;
    };
    const generateDownloadFileName = (filesForDownload)=>{
        let fileName;
        if(filesForDownload?.length > 0){
            if(filesForDownload?.length > 1){
                fileName = props?.currentFolderName?.name+'.zip' ;
            }else{
                if(filesForDownload[0].type == 'file'){
                    fileName = filesForDownload[0].File_Name__c ;
                }else{
                    fileName = filesForDownload[0].title+'.zip' ;
                }
            }
        }
        return fileName;
    };
    const getFileBlob = async (item , exportMarkup) =>{
        let fileBlob;
        try{
            if(instanceRef.current && exportMarkup == true && item.File_Name__c?.toLowerCase().endsWith('.pdf')){
                let fileName = item.File_Name__c;
                let f_item = {url : item.url , markupJSON : item.Markup_Json__c}
                fileBlob = await downloadPdfDocument([f_item],fileName,exportMarkup,instanceRef.current , true);
            }else{
                //console.log('item url',item.url);
                let urrl = item.url;
                //urrl = 'https://cverse-dev.s3.amazonaws.com/a0K3x000017IQuYEAW/a0K3x00000wjmxJEAQ/SampleGIFImage_350kbmb_3601210.gif?AWSAccessKeyId=AKIA6FO5IYHINKWKDQHA&Expires=1727069995&Signature=BCIWuRTuXosis9YFAbPcbt%2Fpi48%3D'
                const fileContent = await fetch(urrl);
                //console.log('boom boom',1);
                fileBlob = await fileContent.blob();
                //console.log('boom boom',2);
            }
        }catch (e){
            console.log('file blob error',e.message);
        }
        return fileBlob;
    }
    const setItemToBgProcess=(queue_obj)=>{
        setBgProcessItems(pv=>{
          let t = cloneDeep(pv);
            if(!t){
              t = [];
            }
            let fi = t.findIndex(k=>k.id === queue_obj.id);
            if(fi > -1){
              t[fi] = queue_obj;
            }else{
              t.push(queue_obj);
            }
          return t;
        });
    };
    return (
        <div style={{ width: foldersExpand ? "81%" : "100%" }}>
            <div ref={pdfContainerRef} style={{ display: 'none' }}></div>
            <div ref={pdfContainerRef1} style={{ display: 'none' }}></div>
            {activeTab === 0 &&
                <>
                    <div className="page_body_right_head">
                        <div className="page_body_right_head_left_side" style={{ width: '73%' }}>
                            {selectedRowIds.length < 1 && selectedNode?.length > 0 &&
                                <div className="folder_path" style={{ width: '100%' }}>
                                    <BreadcrubFolderView
                                        selectedNode={selectedNode}
                                        onSelection={(folder) => {
                                            //console.log("Hello");
                                            setFolderTreeOnView({ id: folder.Id })
                                        }}
                                    />
                                </div>
                            }
                            {selectedRowIds.length > 0 &&
                                <>
                                    <div style={{ display: "flex", paddingTop: "8px", borderRight: "1px solid #EAEAEA", width: "150px", marginRight: "20px" }}>
                                        <div className="buttons" style={{ paddingBottom: "4px", cursor: "pointer", marginRight: "10px" }} onClick={handleCloseIcon}>
                                            <img className="close" src="close.svg" alt="close" style={{ cursor: 'pointer' }} />
                                        </div>
                                        <div>
                                            <h2>{selectedRowIds.length} Selected</h2>
                                        </div>
                                    </div>
                                    <div style={{
                                        borderRight: "1px solid #EAEAEA", borderTop: "1px solid #EAEAEA",
                                        borderBottom: "1px solid #EAEAEA", borderLeft: "1px solid #EAEAEA", display: "flex",
                                    }} >
                                        {selectedRowIds?.length == 1 &&
                                            <div style={{ borderRight: "1px solid #EAEAEA" }} onClick={(e) => { onClickOpenAction('rename', 'header'); }}>
                                                <Tooltip title="Rename" placement="top" PopperProps={{ className: "version-action-tooltip" }} arrow >
                                                    <IconButton sx={{ borderRadius: 0 }}>
                                                        <img src={editIcon} alt="edit icon" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        }

                                        <div style={{ borderRight: "1px solid #EAEAEA" }} onClick={(e) => {
                                        handleOpenExportDialog("basic-menu");
                                        handleCloseMenu();
                                    }}>
                                            <Tooltip title="Export" placement="top" PopperProps={{ className: "version-action-tooltip" }} arrow >
                                                <IconButton sx={{ borderRadius: 0 }}>
                                                    <img src="/download.svg" alt="share" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div style={{ borderRight: "1px solid #EAEAEA" }} onClick={() => { onClickOpenAction('move', 'header'); }}>
                                            <Tooltip title="Move" placement="top" PopperProps={{ className: "version-action-tooltip" }} arrow >
                                                <IconButton sx={{ borderRadius: 0 }} >
                                                    <img style={{ width: "17px" }} src={moveIcon} alt="download" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div style={{ borderRight: "1px solid #EAEAEA" }} onClick={() => { onClickOpenAction('copy', 'header'); }}>
                                            <Tooltip title="Copy" placement="top" PopperProps={{ className: "version-action-tooltip" }} arrow >
                                                <IconButton sx={{ borderRadius: 0 }} >
                                                    <img style={{ width: "17px" }} src={copyIcon} alt="download" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div style={{ borderRight: "1px solid #EAEAEA" }} onClick={() => {
                                            setShareItems(selectedFiles);
                                        }}>
                                            <Tooltip title="Share" placement="top" PopperProps={{ className: "version-action-tooltip" }} arrow >
                                                <IconButton sx={{ borderRadius: 0 }} >
                                                    <img src="/share.svg" alt="share" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div style={{ borderRight: "1px solid #EAEAEA" }} onClick={() => { onClickOpenAction('delete', 'header'); }}>
                                            <Tooltip title="Delete" placement="top" PopperProps={{ className: "version-action-tooltip" }} arrow >
                                                <IconButton sx={{ borderRadius: 0 }}>
                                                    <img src="/delete.svg" alt="share" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        {/* {selectedFileRows.length > 0 && selectedFolderRows.length == 0 &&
                                            <div style={{ borderRight: "1px solid #EAEAEA" }}>
                                                <Tooltip title="Submit for Review" placement="top" PopperProps={{ className: "version-action-tooltip" }} arrow >
                                                    <IconButton sx={{ borderRadius: 0 }} >
                                                        <img src="/submit for review.svg" alt="download" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        } */}

                                    </div>
                                </>
                            }
                        </div>
                        <div className="page_body_right_head_right_side" style={{ marginLeft: 'auto' }}>
                            <div>
                                <TextField
                                    sx={{ "& .MuiInputBase-root": { color: "#505050", height: '37px', padding: "4px" }, }}
                                    id="outlined-basic"
                                    variant="outlined"
                                    fullWidth
                                    value={searchText}
                                    className="Searchbar-field"
                                    autoComplete="off"
                                    placeholder="Search File / Folder"
                                    InputProps={{
                                        maxLength: 2000,
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                {searchBoxFilter ? <img style={{ width: "25px", cursor: "pointer" }} src="/cancel.svg" alt="close icon" onClick={() => { searchListViewFunction(""); }} /> : <Search />}
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => {
                                        //convert input text to lower case
                                        var lowerCase = e.target.value.toLowerCase();
                                        if (!lowerCase || lowerCase === null || lowerCase === "") {
                                            searchListViewFunction(lowerCase);
                                        }
                                        setSearchText(lowerCase);
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            // Do code here
                                            e.preventDefault();
                                            var lowerCase = e.target.value.toLowerCase();
                                            //console.log('searchText ',searchText , 'lowerCase',lowerCase);
                                            searchListViewFunction(searchText);
                                        }
                                    }}
                                />
                            </div>
                            <div className={totalFilterApplied > 0 ? (filterOptionsVisible ? "grid-11" : "grid-111") : (filterOptionsVisible ? "grid-1" : "grid")} onClick={() => { showFilterOptions(!filterOptionsVisible); }}>
                                <img className="filter2" src="/filter2 (1).svg" alt="filter2" />
                                <div className="label-11 poppins-normal-abbey-14px">
                                    Filters {totalFilterApplied > 0 && <>{' (' + totalFilterApplied + ')'}</>}
                                </div>
                                {!filterOptionsVisible && totalFilterApplied > 0 && (
                                    <div style={{ cursor: "pointer" }} className={filterOptionsVisible ? "grid-1" : "grid-2"} onClick={() => { handleClearAllFilters(); }}>
                                        <div className="clear1">
                                            <img className="clear" src="close.svg" alt="close" />
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="switch_button">
                                <div className="switch_icon" style={{ background: !isGridView && "#E8EFFF" }} onClick={() => setIsGridView(false)}>
                                    <img src={navLeft} alt="nevLeft" />
                                </div>
                                <div className="switch_icon" style={{ background: isGridView && "#E8EFFF" }} onClick={() => setIsGridView(true)}>
                                    <img src={grid} alt="gridIcon" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {(searchBoxFilter || contentSearchRunning) &&
                        <div className="bottom_area" style={{ paddingBottom: "16px", paddingLeft: "16px", fontSize: "14px", fontWeight: 400 }}>
                            {searchBoxFilter && <> {viewTableData.length} Search Results for <b>"{searchText}"</b></>}
                            {contentSearchRunning && ' Pdf Search is Running...'}
                        </div>
                    }
                    {filterOptionsVisible && (
                        <Design>
                            <div className="bottom_area" style={{ paddingBottom: "16px" }}>
                                <div className="filters">
                                    <DropdownFilter
                                        credential={credential}
                                        dropLabel='File Types'
                                        isValueArray={true}
                                        options={fileTypeOptions}
                                        activeFilterDropdown={activeFilterDropdown}
                                        setActiveFilterDropdown={setActiveFilterDropdown}
                                        activeFilters={fileTypeActiveFilters}
                                        onFilterChange={async (newValues) => {
                                            processTofilter_.current = true;
                                            setFileTypeActiveFilters(newValues);
                                        }}
                                    />
                                    <DropdownFilter
                                        credential={credential}
                                        dropLabel='Versions'
                                        options={versionOptions}
                                        isValueArray={true}
                                        activeFilterDropdown={activeFilterDropdown}
                                        setActiveFilterDropdown={setActiveFilterDropdown}
                                        activeFilters={versionActiveFilters}
                                        onFilterChange={async (newValues) => {
                                            processTofilter_.current = true;
                                            setVersionActiveFilters(newValues);
                                        }}
                                    />
                                    <DropdownFilter
                                        credential={credential}
                                        dropLabel='Review Status'
                                        options={reviewStageOptions}
                                        isValueArray={true}
                                        activeFilterDropdown={activeFilterDropdown}
                                        setActiveFilterDropdown={setActiveFilterDropdown}
                                        activeFilters={reviewStatusActiveFilters}
                                        onFilterChange={async (newValues) => {
                                            processTofilter_.current = true;
                                            setReviewStatusActiveFilters(newValues);
                                        }}
                                    />
                                    <DropdownFilter
                                        credential={credential}
                                        dropLabel='Updated By'
                                        options={credential?.userList}
                                        activeFilterDropdown={activeFilterDropdown}
                                        setActiveFilterDropdown={setActiveFilterDropdown}
                                        activeFilters={updatedByActiveFilters}
                                        onFilterChange={async (newValues) => {
                                            processTofilter_.current = true;
                                            console.log('updatedByActiveFilters ::', newValues);
                                            setUpdatedByActiveFilters(newValues);
                                        }}
                                    />
                                    {totalFilterApplied > 0 && <>
                                        <div style={{ cursor: "pointer" }} className="tertiary" onClick={handleClearAllFilters}>
                                            <img className="reset" src="reset.svg" alt="reset" />
                                            <div className="filters">Clear All</div>
                                        </div>
                                    </>}
                                </div>
                            </div>
                        </Design>
                    )}
                </>
            }
            {toastMessage &&
                <ToastComponent message={toastMessage} handleClose={() => { setToastMessage(null) }} />
            }
            {activeTab === 0 &&
                <div>
                    {loading && <Spinner />}

                    <>
                        {isGridView &&
                            <GridViewComponent
                                attachmentThumbnail={attachmentThumbnail}
                                callFrom={"FilesComponent"}
                                viewTableData={viewTableData}
                                onRowClickFunc={(parma) => { fileNameClick(parma?.row); }}
                                onRowSelectionChange={onRowSelectionChange}
                                selectedIds={selectedRowIds}
                                handleClickMenu={handleClickMenu}
                                openMenu={openMenu}
                            />
                        }
                        {!isGridView &&
                            <Box className={dragging ? "dropable-zone-table" : ''}
                                onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} style={{ height: filterOptionsVisible ? searchBoxFilter ? "calc(100vh - 260px)": "calc(100vh - 230px)" : searchBoxFilter ? "calc(100vh - 210px)" : "calc(100vh - 180px)",}}>
                                <ReactDataTable
                                    tableHeight={(searchBoxFilter || contentSearchRunning) && "calc(98vh - 217px)"}
                                    errorMessage={searchBoxFilter ? searchErrorMessage : errorMessage}
                                    pagination={false}
                                    setCurrentFileId={setCurrentRowId}
                                    handleClickMenu={handleClickMenu}
                                    setRowsPerPage={setRowsPerPage}
                                    columns_react_table={files_folder_table}
                                    rowss={viewTableData}
                                    openMenu={openMenu}
                                    selectedIds={selectedRowIds}
                                    searchBoxFilter={searchBoxFilter}
                                />
                            </Box>
                        }
                        <input
                            style={{ display: 'none' }}
                            ref={inputRef}
                            type="file"
                            multiple
                            onChange={(e) => uploadMedia(e.target.files)}
                            onClick={(e) => (e.target.value = "")}
                        />
                        <input
                            style={{ display: 'none' }}
                            ref={inputDir}
                            type="file"
                            webkitdirectory="" directory=""
                            onChange={(e) => uploadFolder(e.target.files)}
                            onClick={(e) => (e.target.value = "")}
                        />
                    </>

                </div>
            }
            {activeTab === 1 &&
                <RecycleBin
                    errorMessage={errorMessage}
                    credential={credential}
                    callFrom={"FilesComponent"}
                    onRestore={() => {
                        // event fires upon item restore
                        setItemRestored(true);
                    }}
                    webInstance={instanceRef.current}
                />
            }
            {(false || (pdfopenfiledata?.url && openpopup)) && (
                <ShowPdf
                     viewFolderFiles={pdfDocs}
                    //{id:'a0JOz0000007dELMAY' , folderId : 'a0DDb00000H9lRtMAJ'}
                    credential={credential}
                    pdfopenfiledata={pdfopenfiledata}
                    updateTableState={updateStateTableDataWithRow}
                    viewingIssue={viewingIssueId}
                    handleClose={() => {
                        setOpen(false);
                        setRedirectId(null);
                        setViewingIssue(null);
                    }}
                    callFrom={"FilesComponent"}
                />
            )}
            {confirmDialog === "New Folder" &&
                <NewFolderDialog
                    onCancel={() => {
                        setConfirmDialog(null);
                        setError(false);
                    }}
                    onAccept={(folderName) => {
                        if (props?.currentFolderName.name === folderName) {
                            setError(true);
                        } else {
                            handleNewFolderErrorCheck(folderName);;
                        }
                    }}
                    showBreadcrub={true}
                    error={error}
                    dialogData={confirmDialogData}
                    selectedNode={selectedNode}
                />
            }
            {confirmDialog === "rename" &&
                <RecordRenameDialog
                    onCancel={() => {
                        setConfirmDialog(null);
                        setError(false);
                    }}
                    onAccept={(record, sobjectName, oldName, newName) => {
                        setLoading(true);
                        const formData = new FormData();
                        //let issueObj = sfIssueObj;
                        formData.append("recObj", JSON.stringify(record));
                        axios.post(`${fetchUrl}/recSave/${sobjectName}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData)
                            .then(async (res) => {
                                //console.log("count:::: " + JSON.stringify(res));
                                if (res.data.status === 200) {
                                    if (props?.currentFolderName?.id) {
                                        let tmsg = `${oldName} has been renamed to ${newName}`;
                                        setToastMessage(tmsg);
                                        if (sobjectName === 'Folder__c') {
                                            _getFolderApi({ id: props?.currentFolderName?.id });
                                        }
                                        refreshFolderFiles(props?.currentFolderName.id);
                                    }
                                }
                            }).catch((err) => {
                                console.log('recSave/Set__c', err);
                                setLoading(false);
                            });
                        setConfirmDialog(null);
                        setRenameDialogData(null);
                    }}
                    orgData={viewTableData}
                    dialogData={renameDialogData}
                />
            }
            {moveDialog &&
                <MoveDialog
                    _getFolderApi={_getFolderApi}
                    credential={credential}
                    currentFolderName={props?.currentFolderName}
                    onCancel={() => {
                        setMoveDialog(null);
                    }}
                    onAccept={(uniqObject, _setSpinner) => {
                        //console.log('uniqObject :: ',JSON.stringify(uniqObject));
                        if (uniqObject && uniqObject.targetFolder && (uniqObject?.folders?.length > 0 || uniqObject?.files?.length > 0)) {
                            if (_setSpinner) {
                                _setSpinner(true);
                            }
                            const reqBody = { targetId: uniqObject.targetFolder?.Id, folderIds: [], fileIds: [] };
                            //console.log('uniqObject :: ',uniqObject);
                            reqBody.folderIds = uniqObject?.folders?.length > 0 ? uniqObject.folders.map((sub) => sub.id) : [];
                            reqBody.fileIds = uniqObject?.files?.length > 0 ? uniqObject.files.map((sub) => sub.id) : [];
                            let ac = moveDialog.operation === 'move' ? 'moved' : 'copied';
                            let type = reqBody.fileIds.length > 0 && reqBody.folderIds.length ? 'Item'
                                : reqBody.fileIds.length > 0 ? 'File'
                                    : reqBody.folderIds.length > 0 && 'Folder';
                            let isMultiple = (Number(reqBody.fileIds.length) + Number(reqBody.folderIds.length)) > 1;
                            let toaM = `${type}${isMultiple ? 's' : ''} ${isMultiple ? 'have' : 'has'} been ${ac} from ${props?.currentFolderName?.name} to ${uniqObject.targetFolder?.Name}.`;

                            axios.post(`${fetchUrl}/operation/${moveDialog.operation}/${uniqObject.targetFolder?.Id}/${credential?.projectId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, reqBody)
                                .then(async (res) => {
                                    //console.log("count:::: " + JSON.stringify(res));
                                    _getFolderApi({ id: uniqObject.targetFolder?.Id });
                                    refreshFolderFiles(uniqObject.targetFolder?.Id);
                                    setMoveDialog(null);
                                    setToastMessage(toaM);
                                }).catch((err) => {
                                    console.log('recSave/Set__c', err);
                                    setLoading(false);
                                    if (_setSpinner) {
                                        _setSpinner(false);
                                    }
                                });
                        }
                    }}
                    dialogData={moveDialog}
                    sectionHook={props?.sectionHook}
                />
            }
            {openMenu && (
                <>
                    <Stack direction="row" spacing={2}>
                        <Paper sx={{ height: 320, maxWidth: "100%" }}>
                            <Menu id="basic-menu" anchorEl={anchorEl} open={openMenu} onClose={handleCloseMenu}
                                MenuListProps={{ "aria-labelledby": "basic-button" }} >
                                <div className="dropdown-list" onClick={(e) => { /* handleOpenExportDialog("basic-menu"); handleCloseMenu();*/ }} >
                                    <div className="text-with-icon-1" onClick={(e) => { onClickOpenAction('rename', 'menu'); }}>
                                        <img className="download-source" src={editIcon} alt="download source" />
                                        <div className="label-3 poppins-normal-abbey-14px">
                                            {" "} Rename
                                        </div>
                                    </div>
                                    <div className="text-with-icon-1" onClick={(e) => {
                                        handleOpenExportDialog("basic-menu");
                                        handleCloseMenu();
                                    }}>
                                        <img className="share" src="/download.svg" alt="export" />{" "}
                                        <div className="label-1 poppins-normal-abbey-14px">
                                            {" "}
                                            Export
                                        </div>
                                    </div>
                                    <div className="text-with-icon-1" onClick={() => { onClickOpenAction('move', 'menu'); }}>
                                        <img className="share" src={moveIcon} alt="share" />{" "}
                                        <div className="label-1 poppins-normal-abbey-14px">
                                            {" "}
                                            Move
                                        </div>
                                    </div>
                                    <div className="text-with-icon-1" onClick={() => { onClickOpenAction('copy', 'menu'); }} >
                                        <img className="share" src={copyIcon} alt="export" />{" "}
                                        <div className="label-1 poppins-normal-abbey-14px">
                                            {" "}
                                            Copy
                                        </div>
                                    </div>
                                    <div className="text-with-icon-1" onClick={() => {
                                        setShareItems([selectedMenuRow]);
                                        setAnchorEl(null);
                                    }}>
                                        <img className="pencil" src="/share.svg" alt="Pencil" />{" "}
                                        <div className="label-2 poppins-normal-abbey-14px">
                                            Share
                                        </div>
                                    </div>
                                    <div className="text-with-icon-1" onClick={(e) => { onClickOpenAction('delete', 'menu'); }}>
                                        <img className="download-source" src="/delete.svg" alt="download source" />
                                        <div className="label-3 poppins-normal-abbey-14px">
                                            {" "}
                                            Delete
                                        </div>
                                    </div>
                                    {/* {selectedMenuRow?.type == "file" &&
                                        <div className="text-with-icon-1">
                                            <img className="download-source" src="/submit for review.svg" alt="download source" />
                                            <div className="label-3 poppins-normal-abbey-14px">
                                                {" "}
                                                Submit for Review
                                            </div>
                                        </div>
                                    } */}
                                </div>
                            </Menu>
                        </Paper>
                    </Stack>
                </>
            )}
            {deleteDialogData &&
                <ConfirmDialog
                    onCancel={() => { setDeleteDialogData(null); }}
                    onAccept={() => {
                        if (deleteDialogData.title === "Stop Uploads?") {
                            cancelUploads(uploadQueueFiles);
                            cancelFolderUploads(uploadQueueFolders);
                        } else {
                            if (selectedMenuRow) {
                                onClickDeleteItemSingle(selectedMenuRow);
                            } else {
                                onClickDeleteItems(selectedRowIds);
                            }
                        }
                    }}
                    DialogData={deleteDialogData}
                />
            }
            {toShareItems?.length > 0 && (
                <ShareableLinkComponent
                    setToastMessage={setToastMessage}
                    shareListItems={selectedFiles}
                    onCancel={(links) => {
                        updateTableStateForLink(links);
                        setShareItems([]);
                    }}
                />
            )}
            {activeExportDialog && (
                <ExportFileDialog
                    onAccept={(selectedFiles, exportWithMarkup) => {
                        setExportIndicator(true);
                        setActiveExportDialog(false);
                        let fileName = generateDownloadFileName(selectedFiles);
                        let que_obj = {id : makeRandomId(3)+fileName+'_'+makeRandomId(5) ,
                                            name :fileName , label  :fileName , started : true };
                        setItemToBgProcess(que_obj);
                        handleDownloadFolderFiles(selectedFiles, fileName , exportWithMarkup ).then(failed=>{
                            let succ = !failed;
                            que_obj.processed =true;
                            que_obj.failed = failed;
                            que_obj.completed = !que_obj.failed;
                            setItemToBgProcess(que_obj);
                        }).catch(qe=>{
                            que_obj.processed =true;
                            que_obj.failed = true;
                            que_obj.completed = !que_obj.failed;
                            setItemToBgProcess(que_obj);
                        });
                    }}
                    open={activeExportDialog}
                    handleClose={handleCloseExportDialog}
                    selectedFiles={selectedFiles}
                    callFrom="FilesComponent"
                />
            )}
            <div style={{ position: 'fixed', bottom: 0, right: 0}} >
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', gap: '10px' ,alignItems : 'end'}}>
                    {(uploadQueueFiles?.length > 0 || uploadQueueFolders?.length > 0) && (
                        <FileUploadProgress
                            containerStyle={{ position: 'relative'}}
                            uploadQueueFolders={uploadQueueFolders}
                            uploadQueueFiles={uploadQueueFiles}
                            _handleCloseProcess={_handleCloseProcess}
                        />
                    )}

                    {exportIndicator && (
                        <ItemInQueueComponent
                            containerStyle={{ position: 'relative'}}
                            heading="Exporting"
                            queueItems={bgProcessItems}
                            onClose={() => {
                                setBgProcessItems([]);
                                setExportIndicator(false);
                            }}
                        />
                    )}
                </div>
            </div>

        </div>
    );
}
export const BreadcrubFolderView = (props) => {
    const { selectedNode, onSelection } = props;
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const BreadCrubLable = ({ folder }) => {
        return (
            <Tooltip title={folder.Name} placement="bottom" >
                <span style={{ cursor: "pointer", }} onClick={() => { onSelection_(folder) }}>
                    {folder.Name}
                </span>
            </Tooltip>
        );
    };
    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };
    const onSelection_ = (folder) => {
        //console.log("folder ::", folder);
        setIsPopupOpen(false);
        if (onSelection) {
            onSelection(folder)
        }
    };
    return <>
        {selectedNode?.length > 0 &&
            <>
                {selectedNode.length <= 3 &&
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {selectedNode.slice(0, -1).map((node, index) => (
                            <React.Fragment key={index}>
                                {index > 0 && (
                                    <img src={rightPath} alt="right_icon"
                                        style={{ paddingLeft: "10px", paddingRight: "10px", paddingBottom: "3px", height: "17px" }}
                                    />
                                )}
                                <BreadCrubLable folder={node} />
                            </React.Fragment>
                        ))}
                        {selectedNode.length > 1 && <img style={{ paddingLeft: "10px", paddingRight: "10px", paddingBottom: "3px", height: "17px" }} src={rightPath} alt="right_icon" />}
                        <b style={{ color: "black", cursor: "pointer", }}>
                            <BreadCrubLable folder={selectedNode[selectedNode.length - 1]} />
                        </b>
                    </div>
                }
                {selectedNode.length > 3 &&
                    <>
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            <img src={more} alt="moreICon" onClick={togglePopup} style={{ paddingBottom: "4px", width: "30px", background: isPopupOpen ? "#E8EFFF" : "", height: "30px", cursor: "pointer", }} />
                            <img style={{ paddingLeft: "10px", paddingRight: "10px", paddingBottom: "3px", height: "17px" }} src={rightPath} alt="right_icon" />
                            <BreadCrubLable folder={selectedNode[selectedNode.length - 2]} />
                            <img style={{ paddingLeft: "10px", paddingRight: "10px", paddingBottom: "3px", height: "17px" }} src={rightPath} alt="right_icon" />
                            <b style={{ color: 'black' }}>
                                <BreadCrubLable folder={selectedNode[selectedNode.length - 1]} />
                            </b>

                        </div>
                        {isPopupOpen && (
                            <>
                                <div className="popup">
                                    <ul className="popup-list">
                                        {selectedNode.slice(0, -2).reverse().map((folder, index) => (
                                            <li key={folder.Id} style={{ display: 'flex' }} onClick={() => { onSelection_(folder) }}>
                                                <img style={{ paddingRight: "8px" }} src={folderIcon} alt="right_icon" />
                                                <span title={folder.Name}>{folder.Name}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="outer-div" onClick={() => setIsPopupOpen(false)}></div>
                            </>
                        )}
                    </>
                }
            </>
        }
    </>
}
export const prepareAmazonRecordRowForProjectFile = async (file, cred) =>{
    let retData = await prepareAmazonRecordRow(file, cred , 'Files');
    if(retData.inappurl){
        retData.inappurl += `&folderId=${file.Folder__c}`;
    }
    return retData;
};
export default FolderFilesDataTable;
